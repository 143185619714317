import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { DepositService } from '$lib/service/deposit';

const fetchWithdrawDetail = createAsyncThunk(
	'withdrawDetailsModel/fetchWithdrawDetail',
	async (params: { cashout_id: number }, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(DepositService.fetchWithdrawDetail(params)).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const withdrawDetailsModel = slice({
	name: 'withdrawDetailsModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchWithdrawDetail }
});

export default withdrawDetailsModel;

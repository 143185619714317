import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ReportCentreService } from '$lib/service/report-centre';

const list = createAsyncThunk(
	'reportCentreRecordsModel/list',
	async (_, { dispatch }) => {
		try {
			const { data } = await dispatch(ReportCentreService.reportRecords()).unwrap();
			return data;
		} catch(e) {
			return {};
		}
	}
);

const reportCentreRecordsModel = slice({
	name: 'reportCentreRecordsModel',
	initialState: { records: [] },
	reducers: {
		reset(state) {
			state.records = [];
		},
	},
	extraReducers: {
		[list.fulfilled.type]: (state, { payload }) => {
			state.records = payload.list;
		},
	},
	effect: { list },
});

export default reportCentreRecordsModel;

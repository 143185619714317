import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import BnSureService from '$lib/service/bn-sure/BnSure';

import { IBnSureState } from './types';

const fetchList = createAsyncThunk(
	'bnSureModel/fetchBnSureModelList',
	async (
		params:{
			currentPage?: number,
			pageSize?: number,
			isSortDesc?:number,
			status?:number |string,
			isExcludeUnPay?:number,
			isNeedFilter:number,
		},
		{ dispatch }
	) => {
		const { data } = await dispatch(
			BnSureService.fetchList(omit(params))
		).unwrap();
		return { paginator:data.paginator,status:params.status, data: data.data };
	}
);

const bnSureModel = slice({
	name: 'bnSureModel',
	initialState: <IBnSureState>{
		list: [],
		requestParams: {
			currentPage: 1,
			pageSize: 30,
		},
		hasMore: true,
		storeActiveKey:'',
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload } as IBnSureState;
		},
		initParams: (state) => {
			state.requestParams = { currentPage: 1, pageSize: 30, auditStatus: '', endStatus: ''};
			state.list = [];
			state.hasMore = false;
		},
		setParams: (state, { payload }) => {
			state.requestParams = { ...state.requestParams, ...payload };
		}
	},
	extraReducers: {
		[fetchList.fulfilled.type]: (state, { payload }) => {
			const { data, paginator,status } = payload;
			state.list = paginator?.currentPage === 1 ? data : [...state.list, ...data];
			state.requestParams.currentPage = paginator?.currentPage;
			state.hasMore = data?.length >= paginator?.pageSize;
			state.storeActiveKey = status;
		},
	},
	effect: {fetchList}
});

export default bnSureModel;

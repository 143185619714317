import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import OrdinaryService from '$lib/service/ordinary-customer-complaint/ordinary';

//获取上传地址
const uploadAddr = createAsyncThunk(
	'ordinaryModel/uploadAddr',
	async (params: {}, { dispatch }) => {
		const { data } = await dispatch(OrdinaryService.uploadBeforeQuery(params)).unwrap();
		return { data: data };
	}
);
//上传
const verifyUpLoad = createAsyncThunk(
	'ordinaryModel/queryUpLoad',
	async (params: {}, { dispatch }) => {
		const { data } = await dispatch(OrdinaryService.verifyUpLoad(params)).unwrap();
		return { data: data };
	}
);
//验证
const verifyValidity = createAsyncThunk(
	'ordinaryModel/verifyValidity',
	async (params: {}, { dispatch }) => {
		const { data } = await dispatch(OrdinaryService.verifyValidity(omit(params))).unwrap();
		return { data: error };
	}
);

const ordinaryModel = slice({
	name: 'ordinaryModel',
	initialState: <any>{
		list: [],
		requestParams: {
			currentPage: 1,
			pageSize: 30
		},
		hasMore: true
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload };
		},
		initParams: (state) => {
			// state.requestParams = { currentPage: 1, pageSize: 30, auditStatus: '', endStatus: ''};
			// state.list = [];
			// state.hasMore = false;
		},
		setParams: (state, { payload }) => {
			state.requestParams = { ...state.requestParams, ...payload };
		}
	},
	extraReducers: {
		[verifyValidity.fulfilled.type]: (state, { payload }) => {
			const { data } = payload;
		}
	},
	effect: { verifyUpLoad, verifyValidity, uploadAddr }
});

export default ordinaryModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ComplainService, IFetchComplainConfigParams } from '$lib/service/complain';
import { IAddComplainParams, IComplainConfig } from '$lib/service/complain/types';

export type IComplainState = {
	config: IComplainConfig;
};

export const fetchComplainConfig = createAsyncThunk(
	'complainModel/fetchComplainConfig',
	async (params: IFetchComplainConfigParams, { dispatch }) => {
		const { data } = await dispatch(ComplainService.fetchComplainConfig(params)).unwrap();
		return data;
	}
);

export const addComplain = createAsyncThunk(
	'complainModel/addComplain',
	async (params: IAddComplainParams, { dispatch }) => {
		const { data } = await dispatch(ComplainService.addComplain(params)).unwrap();
		return data;
	}
);

const complainModel = slice({
	name: 'complainModel',
	initialState: {
		config: {}
	} as IComplainState,
	reducers: {
		setComplainConfig: (state, { payload }) => {
			state.config = payload;
		}
	},
	extraReducers: {
		[fetchComplainConfig.fulfilled.type]: (state, { payload }) => {
			state.config = payload;
		}
	},
	effect: {
		fetchComplainConfig,
		addComplain
	}
});

export default complainModel;

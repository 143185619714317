import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { FeedbackService } from '$lib/service/feedback';
import { IFeedbackHistoryData, IFetchFeedbackHistoryReq } from '$lib/service/feedback/types';

export const fetchFeedbackHistory = createAsyncThunk(
	'feedbackHistoryModel.fetchFeedbackHistory',
	async (params: IFetchFeedbackHistoryReq, { dispatch }) => {
		const { data } = await dispatch(FeedbackService.fetchFeedbackHistory(params)).unwrap();
		return { data: data?.data || [], params };
	}
);

const feedbackHistoryModel = slice({
	name: 'feedbackHistoryModel',
	initialState: {
		historyData: [],
		request: {
			/** 业务方id */
			tenatId: 0,
			/** 模板id */
			configId: 0,
			/** 业务id */
			appId: 0,
			pageSize: 10,
			currentPage: 1
		},
		hasMore: false
	},
	reducers: {
		setRequest: (state, { payload }) => {
			state.request = payload;
		},
		setUpdateData: (state, { payload }) => {
			state.historyData.forEach((card: IFeedbackHistoryData) => {
				if (card.id === Number(payload.id)) card.unCount = 0;
			});
		},
		setInitData: (state) => {
			state.historyData = [];
		}
	},
	extraReducers: {
		[fetchFeedbackHistory.fulfilled.type]: (state, { payload }) => {
			const { data } = payload;
			const list = state.historyData || [];
			state.historyData = list.concat(data);
			state.hasMore = data.length >= 10;
			state.request = { ...payload.params, currentPage: payload.params.currentPage + 1 };
		}
	},
	effect: {
		fetchFeedbackHistory
	}
});

export default feedbackHistoryModel;

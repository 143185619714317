import { createAsyncThunk } from '@reduxjs/toolkit';
import { Router } from 'next/router';

import { slice } from '$lib/model/utils';
import wrapper, { IReduxContext } from '$lib/model/wrapper/ypStore';
import { YpStoreService } from '$lib/service/yp-store';
import { getReduxCtxSync } from '$lib/utils/getReduxContext';

import { IGroupBookingOrderDetailState } from './types';

const fetchDetail = createAsyncThunk(
	'groupBookingOrderDetailModel/fetchDetail',
	async ({ purchaseOrderNo }: { purchaseOrderNo: string }, { dispatch }) => {
		const { data } = await dispatch(
			YpStoreService.fetchGroupBookingOrderDetail({ purchaseOrderNo })
		).unwrap();
		return data;
	}
);
const groupBookingOrderDetailModel = slice({
	name: 'groupBookingOrderDetailModel',
	initialState: <IGroupBookingOrderDetailState>{ info: {} },
	reducers: {
		initState(state) {
			return { ...state, info: {} } as IGroupBookingOrderDetailState;
		}
	},
	extraReducers: {
		[fetchDetail.fulfilled.type]: (state, { payload }) => {
			state.info = payload;
		}
	},
	effect: { fetchDetail }
});
Router.events.on('routeChangeComplete', (e) => {
	if (e.indexOf('/store/group-booking/manage') > -1) {
		getReduxCtxSync<IReduxContext>(wrapper).dispatch(
			groupBookingOrderDetailModel.actions.initState()
		);
	}
});
export default groupBookingOrderDetailModel;

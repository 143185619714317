import { createAsyncThunk } from "@reduxjs/toolkit";

import { slice } from '$lib/model/utils';
import { actions, AppState } from '$lib/model/wrapper/diamondArea'
import { DiamondService } from "$lib/service/diamond-area";

import { IDiamondState } from './type'

/* 获取埋点时间次数 */
export const fetchBury = createAsyncThunk(
  'diamondModel/fetchBury',
  async (_, { dispatch }) => {
    const { data } = await dispatch(
      DiamondService.fetchBury({ action: "*" })
    ).unwrap();
    return data;
  }
)
/* 获取导航条数据 */
export const fetchTabs = createAsyncThunk(
  'diamondModel/fetchTabs',
  async (params: { templateType: number; industryIdStr: string; occIdStr: string; hHeaders: any }, { dispatch }) => {
    const { data } = await dispatch(
      DiamondService.fetchTabs({ templateType: params.templateType, industryIdStr: params.industryIdStr, occIdStr: params.occIdStr }, params.hHeaders)
    ).unwrap();
    return data;
  }
);
/* 获取基础列表数据 */
export const fetchList = createAsyncThunk(
  'diamondModel/fetchList',
  async (
    params: {
      currentPage?: number,
      pageSize?: number,
      replenish?: boolean,
      templateType: number,
      industryIds?: number[],
      occIds?: number[],
      keywords?: string,
      addTimePeriod?: any,
      sortTimePeriod?: any,
      selfPublish?: any,
      pageSource: string | number,
      hHeaders: any,
      checkDegreeType?: string,
      sortType?: string,
    },
    { dispatch }
  ) => {
    const baseParams: any = {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      replenish: params.replenish,
      templateType: params.templateType,
      industryIds: params.industryIds,
      occIds: params.occIds,
      keywords: params.keywords,
      addTimePeriod: params.addTimePeriod,
      sortTimePeriod: params.sortTimePeriod,
      selfPublish: params.selfPublish,
      pageSource: params.pageSource,
    };
    if (String(params?.templateType) == '4') {
      if (Object.keys(params).indexOf("checkDegreeType") > 0) {
        baseParams.checkDegreeType = Number(params.checkDegreeType);
      }
      if (Object.keys(params).indexOf("sortType") > 0) {
        baseParams.sortType = Number(params.sortType);
      }
    }
    const { data } = await dispatch(
      DiamondService.fetchList(baseParams, params.hHeaders)
    ).unwrap();
    return data;
  }
);

/* 获取补列表数据 */
export const fetchRepairList = createAsyncThunk(
  'diamondModel/fetchRepairList',
  async (
    params: {
      currentPage?: number,
      pageSize?: number,
      replenish?: boolean,
      templateType: number,
      industryIds?: number[],
      occIds?: number[],
      keywords?: string,
      addTimePeriod?: any,
      sortTimePeriod?: any,
      selfPublish?: any,
      pageSource: string | number,
      hHeaders: any,
      checkDegreeType?: number,
      sortType?: number,
    },
    { dispatch }
  ) => {
    const baseParams: any = {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      replenish: params.replenish,
      templateType: params.templateType,
      industryIds: params.industryIds,
      occIds: params.occIds,
      keywords: params.keywords,
      addTimePeriod: params.addTimePeriod,
      sortTimePeriod: params.sortTimePeriod,
      selfPublish: params.selfPublish,
      pageSource: params.pageSource,
    };
    if (String(params?.templateType) == '4') {
      if (Object.keys(params).indexOf("checkDegreeType") > 0) {
        baseParams.checkDegreeType = Number(params.checkDegreeType);
      }
      if (Object.keys(params).indexOf("sortType") > 0) {
        baseParams.sortType = Number(params.sortType);
      }
    }
    const { data } = await dispatch(
      DiamondService.fetchList(baseParams, params.hHeaders)
    ).unwrap();
    return data;
  }
);
/* 过滤末级地区ID */
export const getLastId = (p: { provinceId?: string; cityId?: string; areaId?: string; }) => {
  const { provinceId, cityId, areaId } = p;
  if (provinceId == '' && cityId == '' && areaId == '') {
    return '';
  } else {
    if (areaId != '') return areaId;
    if (cityId != '') return cityId;
    if (provinceId != '') return provinceId;
  }
};
/* 获取兼职工种数据 */
export const fetchJobTag = createAsyncThunk(
  'diamondModel/fetchJobTag',
  async (params: {}, { dispatch }) => {
    const { data } = await dispatch(DiamondService.fetchJobTag(params)).unwrap();
    return data;
  }
)
/* 获取基础列表数据-5号模板 */
export const fetchListNew = createAsyncThunk(
  'diamondModel/fetchList',
  async (
    params: {
      currentPage?: number,
      pageSize?: number,
      replenish?: boolean,
      templateType: number,
      keywords?: string,
      addTimePeriod?: any,
      sortTimePeriod?: any,
      selfPublish?: any,
      pageSource: string | number,
      useIpLocation?: boolean,
      partTimeOcc?: number[],
      hHeaders: any,
      areaInfo?: { provinceId?: string; cityId?: string; areaId?: string; }
    },
    { dispatch }
  ) => {
    const endParams: any = {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      replenish: params.replenish,
      templateType: params.templateType,
      keywords: params.keywords,
      addTimePeriod: params.addTimePeriod,
      sortTimePeriod: params.sortTimePeriod,
      selfPublish: params.selfPublish,
      pageSource: params.pageSource,
      useIpLocation: params.useIpLocation,
      partTimeOcc: params.partTimeOcc,
    }
    const areaId = getLastId(params?.areaInfo as any);
    if (areaId != '') { endParams.areaId = areaId }
    await dispatch(actions.diamondModel.setCityParams({ ...params.areaInfo }));
    const { data } = await dispatch(
      DiamondService.fetchList(endParams, params.hHeaders)
    ).unwrap();
    return data;
  }
);
/* 获取补列表数据-5号模板 */
export const fetchRepairListNew = createAsyncThunk(
  'diamondModel/fetchRepairList',
  async (
    params: {
      currentPage?: number,
      pageSize?: number,
      replenish?: boolean,
      templateType: number,
      keywords?: string,
      addTimePeriod?: any,
      sortTimePeriod?: any,
      selfPublish?: any,
      pageSource: string | number,
      useIpLocation?: boolean,
      partTimeOcc?: number[],
      hHeaders: any,
      areaInfo?: { provinceId?: string; cityId?: string; areaId?: string; }
    },
    { dispatch, getState }
  ) => {
    const endParams: any = {
      currentPage: params.currentPage,
      pageSize: params.pageSize,
      replenish: params.replenish,
      templateType: params.templateType,
      keywords: params.keywords,
      addTimePeriod: params.addTimePeriod,
      sortTimePeriod: params.sortTimePeriod,
      selfPublish: params.selfPublish,
      pageSource: params.pageSource,
      useIpLocation: params.useIpLocation,
      partTimeOcc: params.partTimeOcc
    };
    const areaId = getLastId(params?.areaInfo as any);
    if (areaId != '') { endParams.areaId = areaId }
    await dispatch(actions.diamondModel.setCityParams({ ...params.areaInfo }));
    const { data } = await dispatch(
      DiamondService.fetchList(endParams, params.hHeaders)
    ).unwrap();
    let resList = data?.list?.map((item: any, idx: number) => {
      return {
        ...item,
        pagination_location: String(idx + 1),
        pagination: String(endParams.currentPage)
      }
    }) || []
    const state = { ...(getState() as AppState).diamondModel };
    if (endParams.currentPage === 1) {
      state.repairList = resList;
      state.repairTotal = data?.total
    } else {
      state.repairList = !!state?.repairList?.length ? state?.repairList.concat(resList) : resList;
    }

    // 如果当前页list里没有数据，也认为没有了
    state.repairHasMore = state.repairList.length < (data?.total || 0) && state.repairList.length !== 0

    state.repairList = state?.repairList?.map((item: any, idx: number) => {
      return {
        ...item,
        location_id: String(idx + 1)
      }
    })
    dispatch(actions.diamondModel.setState({ ...state }));
    return data;
  }
);
/* 获取IP解析的城市信息 */
export const fetchIpResolve = createAsyncThunk(
  'diamondModel/fetchIpResolve',
  async (params: {}, { dispatch }) => {
    const { data } = await dispatch(DiamondService.fetchIpResolve({})).unwrap();
    await dispatch(actions.diamondModel.setIpResolve(data));
    return data;
  }
)

const diamondModel = slice({
  name: 'diamondModel',
  initialState: <IDiamondState>{
    list: [],
    repairList: [],
    requestParams: {
      currentPage: 1,
    },
    repairRequestParams: {
      currentPage: 1,
    },
    buried_point_data: {
      appear_num: 60,
      appear_time: 1
    },
    hasMore: true,
    repairHasMore: true,
    total: 0,
    repairTotal: 0,
    totalPage: 0,
    storeActiveKey: '',
    tabs: [
      {
        name: '',
        id: 0,
        type: 0
      }
    ],
    cityParams: {
      areaId: '',
      provinceId: '',
      cityId: '',
      pageNum: 1,
      pageSize: 20,
    },
    jobData: [],
    hasAuth: false,
    useIpResolve: false,
    resolveCity: {}
  } as any,
  reducers: {
    setState: (state, { payload }) => {
      return { ...state, ...payload } as IDiamondState;
    },


    initParams: (state) => {
      state.requestParams = { currentPage: 1, pageSize: 50 };
      state.list = [];
      state.hasMore = false;
    },
    setParams: (state, { payload }) => {
      state.requestParams = { ...state.requestParams, ...payload };
    },
    setRepairParams: (state, { payload }) => {
      state.requestParams = { ...state.repairRequestParams, ...payload };
    },
    // 列表请求参数
    setCityParams: (state, { payload }) => {
      state.cityParams = { ...state.cityParams, ...payload };
    },
    // 职位标签数据
    setJobData: (state, { payload }) => {
      state.jobData = { ...payload };
    },
    // 定位权限状态
    setHasAuth: (state, { payload }) => {
      state.hasAuth = payload;
    },
    // 是否需要IP解析
    setUseIpResolve: (state, { payload }) => {
      state.useIpResolve = payload;
    },
    // IP解析得到城市
    setIpResolve: (state, { payload }) => {
      state.resolveCity = { ...payload };
    },
  },
  extraReducers: {
    [fetchTabs.fulfilled.type]: (state, { payload }) => {
      state.tabs = payload.list;
    },
    [fetchList.fulfilled.type]: (state, { payload }) => {
      let resList = payload?.list?.map((item: any, idx: number) => {
        return {
          ...item,
          pagination_location: String(idx + 1),
          pagination: String(state.requestParams.currentPage),
        }
      }) || []
      if (state.requestParams.currentPage === 1) {
        state.list = resList;
        state.total = payload?.total
      } else {
        state.list = !!state?.list?.length ? state?.list.concat(resList) : resList;
      }
      state.totalPage = payload?.total
      state.list = state?.list?.map((item: any, idx: number) => {
        return {
          ...item,
          location_id: String(idx + 1)
        }
      })

      // 如果当前页list里没有数据，也认为没有了
      state.hasMore = state.list?.length < state.totalPage && state.list?.length !== 0

    },
    [fetchRepairList.fulfilled.type]: (state, { payload }) => {
      let resList = payload?.list?.map((item: any, idx: number) => {
        return {
          ...item,
          pagination_location: String(idx + 1),
          pagination: String(state.repairRequestParams.currentPage)
        }
      }) || []
      if (state.repairRequestParams.currentPage === 1) {
        state.repairList = resList;
        state.repairTotal = payload?.total
      } else {
        state.repairList = !!state?.repairList?.length ? state?.repairList.concat(resList) : resList;
      }
      state.repairList = state?.repairList?.map((item: any, idx: number) => {
        return {
          ...item,
          location_id: String(idx + 1)
        }
      })

      // 如果当前页list里没有数据，也认为没有了
      state.repairHasMore = state.repairList.length < state.repairTotal && state.repairList.length !== 0
    },
    [fetchListNew.fulfilled.type]: (state, { payload }) => {
      let resList = payload?.list?.map((item: any, idx: number) => {
        return {
          ...item,
          pagination_location: String(idx + 1),
          pagination: String(state.requestParams.currentPage),
        }
      }) || []
      if (state.requestParams.currentPage === 1) {
        state.list = resList;
        state.total = payload?.total
      } else {
        state.list = !!state?.list?.length ? state?.list.concat(resList) : resList;
      }
      state.totalPage = payload?.total
      state.list = state?.list?.map((item: any, idx: number) => {
        return {
          ...item,
          location_id: String(idx + 1)
        }
      })

      // 如果当前页list里没有数据，也认为没有了
      state.hasMore = state.list?.length < state.totalPage && state.list?.length !== 0

    },
    [fetchBury.fulfilled.type]: (state, { payload }) => {
      state.buried_point_data = payload.buried_point_data
    },
    [fetchJobTag.fulfilled.type]: (state, { payload }) => {
      state.jobData = { ...payload.response }
    }
  },
  effect: {
    fetchList,
    fetchListNew,
    fetchTabs,
    fetchRepairList,
    fetchRepairListNew,
    fetchBury,
    fetchJobTag,
    fetchIpResolve
  }
});

export default diamondModel;
import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IFetchOrderListReq, YpStoreService } from '$lib/service/yp-store';

const fetchList = createAsyncThunk(
	'ypStoreOrderModel/fetchList',
	async (params: IFetchOrderListReq, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchOrderList(params)).unwrap();
		return data;
	}
);
const ypStoreOrderModel = slice({
	name: 'ypStoreOrderModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchList }
});

export default ypStoreOrderModel;

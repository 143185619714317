import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { DepositService, ISubmitWithdrawParams } from '$lib/service/deposit';

const fetchWithdrawAmount = createAsyncThunk<{ amount: string; cashouted: number }>(
	'withdrawApply/fetchWithdrawAmount',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(DepositService.fetchWithdrawAmount()).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const submit = createAsyncThunk(
	'withdrawApply/submit',
	async (params: ISubmitWithdrawParams, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(DepositService.submitWithdraw(params)).unwrap();
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);
const withdrawApply = slice({
	name: 'withdrawApply',
	initialState: {
		cashouted: 0
	},
	reducers: {},
	effect: { fetchWithdrawAmount, submit },
	extraReducers: {
		[fetchWithdrawAmount.fulfilled.type]: (state, { payload }) => {
			state.cashouted = payload.cashouted;
		}
	}
});

export default withdrawApply;

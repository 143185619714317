import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IFetchSecondHandContactRecordParams,
	ISecondHandContactRecordData,
	SecondHandService
} from '$lib/service/second-hand';

import { ISecondHandContactRecordState } from './types';

const fetchContactRecord = createAsyncThunk(
	'secondHandContactRecordModel/fetchContactRecord',
	async (
		params: Partial<IFetchSecondHandContactRecordParams> | undefined,
		{ dispatch, getState }
	) => {
		const {
			secondHandContactRecordModel: { requestParams }
		} = <{ secondHandContactRecordModel: ISecondHandContactRecordState }>getState();
		const tParams = params ? { ...requestParams, ...params } : requestParams;
		const { data } = await dispatch(SecondHandService.fetchContactRecord(tParams)).unwrap();
		return { params: tParams, data };
	}
);

const fetchPhone = createAsyncThunk(
	'secondHandContactRecordModel/fetchPhone',
	async (params: { id: number | string }, { dispatch }) => {
		const { data } = await dispatch(SecondHandService.fetchContactPhoneNumber(params)).unwrap();
		return data;
	}
);
const secondHandContactRecordModel = slice({
	name: 'secondHandContactRecordModel',
	initialState: <ISecondHandContactRecordState>{
		list: [],
		hasMore: false,
		requestParams: {
			pageNum: 1,
			pageSize: 15
		}
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload };
		},
		setParams: (state, { payload }) => {
			state.requestParams = { ...state.requestParams, ...payload };
		}
	},
	extraReducers: {
		[fetchContactRecord.fulfilled.type]: (
			state,
			{
				payload
			}: {
				payload: {
					data: ISecondHandContactRecordData;
					params: IFetchSecondHandContactRecordParams;
				};
			}
		) => {
			if (payload) {
				const { data, params } = payload;
				if (params.pageNum === 1) {
					state.list = data?.data ?? [];
				} else {
					state.list = data?.data ? state.list.concat(data.data) : state.list;
				}
				state.requestParams = { ...state.requestParams, ...params };
				state.hasMore = !!data?.data?.length;
			}
		}
	},
	effect: { fetchContactRecord, fetchPhone }
});

export default secondHandContactRecordModel;

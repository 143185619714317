import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import { AppState } from '$lib/model/wrapper/deposit';
import { DepositService, IWithdrawListParams } from '$lib/service/deposit';

const fetchWithdrawList = createAsyncThunk(
	'withdrawListModel/fetchWithdrawList',
	async (
		params: IWithdrawListParams & { newest?: boolean },
		{ dispatch, getState, rejectWithValue }
	) => {
		const {
			pagination: { page, page_size }
		} = (getState() as AppState).withdrawListModel;
		if (params.newest) {
			const currentPage = page;
			params.page_size = currentPage * page_size;
			params.max_size = currentPage * page_size;
			params.page = 1;
		}
		try {
			const { data } = await dispatch(
				DepositService.fetchWithdrawList(omit(params, ['newest']))
			).unwrap();
			return { data, newest: params?.newest };
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const fetchWithdrawAmount = createAsyncThunk<{ amount: string; cashouted: number }>(
	'withdrawListModel/fetchWithdrawAmount',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(DepositService.fetchWithdrawAmount()).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);
const withdrawListModel = slice({
	name: 'withdrawListModel',
	initialState: {
		list: [],
		pagination: {
			page: 1,
			page_size: 20,
			has_more: false
		},
		cashouted: 0
	},
	extraReducers: {
		[fetchWithdrawList.fulfilled.type]: (state, { payload }) => {
			const { data, newest } = payload;
			if (data.pagination.page === 1) {
				state.list = data?.list;
			} else {
				state.list = !!state?.list?.length ? state?.list.concat(data?.list) : data?.list;
			}
			if (!newest) {
				state.pagination = data.pagination;
			}
		},
		[fetchWithdrawAmount.fulfilled.type]: (state, { payload }) => {
			state.cashouted = payload.cashouted;
		}
	},
	effect: { fetchWithdrawList, fetchWithdrawAmount },
	reducers: {
		setInitList: (state) => {
			state.list = [];
		}
	}
});
export default withdrawListModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { actions } from '$lib/model/wrapper/feedback';
import { FeedbackService } from '$lib/service/feedback';
import { IfetchFeedbackDetailsReq } from '$lib/service/feedback/types';

export const fetchFeedbackDetails = createAsyncThunk(
	'feedbackDetailsModel.fetchFeedbackDetails',
	async (params: IfetchFeedbackDetailsReq, { dispatch }) => {
		const { data } = await dispatch(FeedbackService.fetchFeedbackDetails(params)).unwrap();
		dispatch(actions.feedbackHistoryModel.setUpdateData({ id: params.id }));
		return data;
	}
);

const feedbackDetailsModel = slice({
	name: 'feedbackDetailsModel',
	initialState: {
		data: {
			/** 反馈时间 */
			createdAt: '',
			/** 应用id	 */
			appId: 0,
			/** 反馈图片	 */
			recordFiles: [],
			/** 反馈留言id	 */
			id: 0,
			/** 反馈内容 */
			content: '',
			/** 反馈回复信息 */
			interactions: [{ content: '', files: [], handleDate: "" }]
		}
	},
	reducers: {},
	extraReducers: {
		[fetchFeedbackDetails.fulfilled.type]: (state, { payload }) => {
			return { ...state, data: payload };
		}
	},
	effect: {
		fetchFeedbackDetails
	}
});

export default feedbackDetailsModel;

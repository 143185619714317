import { createAsyncThunk } from '@reduxjs/toolkit';

import { CreditsService, IWxAuthorizationParams } from '$lib/service/credits';
import { UserService } from '$lib/service/user';
import { YpStoreService } from '$lib/service/yp-store';

import { slice } from '../utils';

const fetchUserInfo = createAsyncThunk('userModel/fetchUserInfo', async (_, { dispatch }) => {
	const { data } = await dispatch(UserService.fetchUserInfo()).unwrap();
	return data;
});

// 获取用户积分
const fetchUserIntegral = createAsyncThunk(
	'userModel/fetchUserIntegral',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchUserIntegral()).unwrap();
		return data;
	}
);

const fetchRealNameStatus = createAsyncThunk(
	'userModel/fetchRealNameStatus',
	async (params: { wechat_token: string } | undefined, { dispatch }) => {
		const { data } = await dispatch(UserService.fetchRealNameStatus(params)).unwrap();
		return data;
	}
);

const fetchWechatInfo = createAsyncThunk(
	'authorizationModel/fetchWechatInfo',
	async (params: IWxAuthorizationParams, { dispatch }) => {
		const { data } = await dispatch(CreditsService.fetchWechatInfo(params)).unwrap();
		return data;
	}
);

const userModel = slice({
	name: 'userModel',
	initialState: {
		token: '',
		top: 0,
		bottom: 0,
		source: 1,
		mode: 1,
		userInfo: {} as any,
		realNameInfo: {} as any,
		openId: '',
		integral: '',
		temporaryIntegral: ''
	},
	reducers: {
		setToken: (state, { payload }) => {
			return { ...state, token: payload };
		},
		setCookies: (state, { payload }) => {
			return { ...state, ...payload };
		}
	},
	extraReducers: {
		[fetchUserInfo.fulfilled.type]: (state, { payload }) => {
			state.userInfo = payload;
		},
		[fetchRealNameStatus.fulfilled.type]: (state, { payload }) => {
			state.realNameInfo = payload;
		},
		[fetchWechatInfo.fulfilled.type]: (state, { payload }) => {
			state.openId = payload?.openId || '';
		},
		[fetchUserIntegral.fulfilled.type]: (state, { payload }) => ({
			...state,
			integral: payload.integral,
			temporaryIntegral: payload.temporary_integral
		})
	},
	effect: { fetchUserInfo, fetchRealNameStatus, fetchWechatInfo, fetchUserIntegral }
});
/** 需要存放至cookie时,导出key值（固定写法） */
export const subtrees = [
	{ subtree: 'userModel.token', compress: false },
	{ subtree: 'userModel.openId', compress: false }
];
export default userModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	CampaignRecruitmentService,
	IPurchaseDetailParamsReq,
	IPurchaseDetailRes
} from '$lib/service/campaign-recruitment';
import { CarouselInfoReq, CarouselInfoRes, CommonOrderService } from '$lib/service/common-order';
import { CommonOrderPayService, IGoodsOrderPayParams, IGoodsOrderPayRes, IosRmbBuyLimitRiskCheck, IPreOrderNo, IPurchaseResultRes, IQueryHitGrayParams, IQueryHitGrayQueryData } from '$lib/service/common-order-pay'
import { YpStoreService } from '$lib/service/yp-store';
import BuryPoint from '$lib/utils/burypoint';

import { CampaignRecruitmentServiceState } from './types';

/** 商品详情 */
export const fetchTMarketGoodsGroupPurchaseDetail = createAsyncThunk<IPurchaseDetailRes, IPurchaseDetailParamsReq>(
	'CampaignRecruitmentModel/fetchTMarketGoodsGroupPurchaseDetail',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CampaignRecruitmentService.fetchTMarketGoodsGroupPurchaseDetail(params)).unwrap();
		return data;
	})

/** 轮播用户 */
export const fetchOrderBuyrCarousel = createAsyncThunk<CarouselInfoRes, CarouselInfoReq>(
	'CampaignRecruitmentModel/fetchOrderBuyrCarousel',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonOrderService.fetchOrderBuyrCarousel(params)).unwrap();
		return data;
	})

export const fetchUserIntegral = createAsyncThunk(
	'CampaignRecruitmentModel/fetchUserIntegral',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchUserIntegral()).unwrap();
		return data;
	})

/** 提交订单 */
const submit = createAsyncThunk<IGoodsOrderPayRes, IGoodsOrderPayParams>(
	'CampaignRecruitmentModel/submit',
	async ({ isNewPurchase, ...params }, { dispatch, rejectWithValue }) => {
		try {
			BuryPoint.collectEvent('InterfaceReturn', {
				interface_name: 'h5提交订单接口查询',
				isNewPurchase: isNewPurchase!,
			});
			const service = isNewPurchase ? CommonOrderPayService.createOrderV2 : CommonOrderPayService.createOrder;
			const { data } = await dispatch(service(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);

/** 限制检查风控 */
const getIosRmbBuyLimitRiskCheck = createAsyncThunk<
	IosRmbBuyLimitRiskCheck,
	{ riskBusinessType: 'YUPAO_POINTS_CHARGE' | 'YUPAO_B_VIP' }
>('CampaignRecruitmentModel/voucherRetain', async (params, { dispatch }) => {
	try {
		const { data } = await dispatch(CommonOrderPayService.getIosRmbBuyLimitRiskCheck(params)).unwrap();
		return data;
	} catch (e: any) {
		return e;
	}
});

/** 查询支付是否走工行 */
const fetchHitGray = createAsyncThunk<IQueryHitGrayQueryData, IQueryHitGrayParams>(
	'CampaignRecruitmentModel/fetchHitGray',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(CommonOrderPayService.queryHitGray(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);

/** 获取预下单号（开启工行支付时才调用） */
const fetchPreOrderNo = createAsyncThunk<IPreOrderNo>(
	'CampaignRecruitmentModel/fetchPreOrderNo',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(CommonOrderPayService.fetchPreOrderNo()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);

/**
 * 获取支付结果
 */
const fetchPurchaseResult = createAsyncThunk<IPurchaseResultRes, string>(
	'CampaignRecruitmentModel/fetchPurchaseResult',
	async (tradeNo, { dispatch }) => {
		const { data } = await dispatch(CommonOrderPayService.fetchPurchaseResult({ tradeNo })).unwrap();
		return data;
	}
);

const CampaignRecruitmentModel = slice({
	name: 'CampaignRecruitmentModel',
	initialState: <CampaignRecruitmentServiceState>{
		purchaseDetail: {},
		carouselInfoRes: {},
		integral: {}
	},
	reducers: {
		setState(state, { payload }) { }
	},
	extraReducers: {
		[fetchTMarketGoodsGroupPurchaseDetail.fulfilled.type]: (state, { payload }) => {
			state.purchaseDetail = payload;
		},
		[fetchOrderBuyrCarousel.fulfilled.type]: (state, { payload }) => {
			state.carouselInfoRes = payload;
		},
		[fetchUserIntegral.fulfilled.type]: (state, { payload }) => {
			state.integral = payload;
		},
	},
	effect: {
		fetchTMarketGoodsGroupPurchaseDetail,
		fetchOrderBuyrCarousel,
		submit,
		fetchHitGray,
		fetchPreOrderNo,
		fetchPurchaseResult,
		fetchUserIntegral,
		getIosRmbBuyLimitRiskCheck
	},
});


export default CampaignRecruitmentModel;

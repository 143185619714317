import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ApplyReq, WithdrawalService } from '$lib/service/withdrawal';

/** 获取账户信息 */
const getAccountMoney = createAsyncThunk(
	'getDetailsModel/getAccountMoney',
	async (params: { active_id: string }, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getAccountMoney(params)).unwrap();
		return data;
	}
);

/** 获取提现详情 */
const getWithdrawalDetails = createAsyncThunk(
	'getDetailsModel/getWithdrawalDetails',
	async (params: { cashout_id: number }, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getDetails(params)).unwrap();
		return data;
	}
);

/** 申请提现 */
const fetchApplyWithdrawal = createAsyncThunk(
	'getDetailsModel/fetchApplyWithdrawal',
	async (params: ApplyReq, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(WithdrawalService.applyWithdrawal(params)).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const getDetailsModel = slice({
	name: 'getDetailsModel',
	initialState: {},
	reducers: {},
	effect: { getWithdrawalDetails, getAccountMoney, fetchApplyWithdrawal }
});
export default getDetailsModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import BnSureService from '$lib/service/bn-sure/BnSure';

import { IBnSureState } from './types';

/* 保险列表获取 */
export const getListPackage = createAsyncThunk<any, any>(
	'bnSureListModel/getListPackage',
	async (_, { dispatch }) => {
		const { data } = await dispatch(BnSureService.getListPackage()).unwrap();
		return data;
	}
);

/* 获取验证码 */
export const fetchLoginLgnore = createAsyncThunk<any, any>(
	'bnSureListModel/fetchLoginLgnore',
	async (params: { biz: string; tel: string; hHeaders: any }, { dispatch }) => {
		const { data } = await dispatch(
			await BnSureService.fetchLoginLgnore({ biz: params.biz, tel: params.tel }, params.hHeaders)
		).unwrap();
		return data;
	}
);
/* 登录 */
export const fetchCodeLogin = createAsyncThunk<any, any>(
	'bnSureListModel/fetchCodeLogin',

	async (
		params: {
			code: string;
			tel: string;
			verifyToken: string;
			hHeaders: any;
			shareReq: {
				refTenantId?: string;
				shareSource?: string;
				trackSeed?: string;
			};
		},
		{ dispatch }
	) => {
		const { data } = await dispatch(
			BnSureService.fetchCodeLogin(
				{
					code: params.code,
					tel: params.tel,
					verifyToken: params.verifyToken,
					shareReq: {
						refTenantId: '',
						shareSource: '',
						trackSeed: ''
					}
				},
				params.hHeaders
			)
		).unwrap();
		return data;
	}
);
/* 初始化信息 */
export const fetchInitData = createAsyncThunk<any, any>(
	'bnSureListModel/fetchInit',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(await BnSureService.fetchInit()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
const ListData = {
	packages: [
		{
			type: 0,
			tppName: '',
			url: '',
			title: '',
			tags: [],
			dsec: '',
			price: ''
		}
	]
};
const bnSureListModel = slice({
	name: 'bnSureListModel',
	initialState: <IBnSureState>ListData,
	reducers: {
		initState() {
			return ListData;
		},
		setState(state, { payload }) {
			return { ...state, ...payload };
		}
	},
	extraReducers: {
		[getListPackage.fulfilled.type]: (state, { payload }) => {
			state.packages = payload;
		}
	},
	effect: {
		getListPackage,
		fetchLoginLgnore,
		fetchCodeLogin,
		fetchInitData
	}
});

export default bnSureListModel;

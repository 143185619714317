import { createAsyncThunk } from '@reduxjs/toolkit';

import { dialog } from '$components/shared/dialog';
import { toast } from '$components/shared/toast';
import { slice } from '$lib/model/utils';
import {
	CreditsService,
	IFetchRechargeStatusData,
	IRechargeCreditsData,
	IRechargeCreditsParams
} from '$lib/service/credits';
import BuryPoint from '$lib/utils/burypoint';
import { ICreditsPCRechargeState } from '$pages/credits-pc/recharge/model/types';

const fetchCreditConfig = createAsyncThunk<ICreditsPCRechargeState['config'], { key: string }>(
	'creditsMRechargeModel/fetchCreditConfig',
	async (params, { dispatch }) => {
		const { data } = (await dispatch(CreditsService.fetchCreditConfig(params)).unwrap()) as any;
		return {
			activityName: data?.activityName,
			activityStatus: data?.activityStatus,
			activityDesc: data?.activityDesc,
			...data.rightList?.[0]
		};
	}
);
const fetchVerifyCode = createAsyncThunk(
	'creditsPCRechargeModel/fetchVerifyCode',
	async (params: { phoneNumber: string }, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(CreditsService.fetchVerifyCode(params)).unwrap();
			return data;
		} catch (e: any) {
			if ([19020000, 19020013].includes(e?.code)) {
				BuryPoint.collectEvent('userPopupExposure', {
					name: e?.code === 19020000 ? '验证码错误弹框' : '未注册提示弹框'
				});
			}
			if (e?.code === 19020013) {
				dialog.show({
					title: '温馨提示',
					content: '此手机号还未在鱼泡网注册，无法充值。',
					hideCancelButton: true
				});
			} else if (e?.message) {
				toast.show(e?.message);
			}
			return rejectWithValue(e);
		}
	}
);

const recharge = createAsyncThunk<IRechargeCreditsData, IRechargeCreditsParams>(
	'creditsMRechargeModel/recharge',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(CreditsService.rechargeCredits(params)).unwrap();
			return data;
		} catch (e: any) {
			if (e?.message) toast.show(e?.message);
			return rejectWithValue(e);
		}
	}
);

const fetchRechargeStatus = createAsyncThunk<IFetchRechargeStatusData, { tradeKey: string }>(
	'creditsPCRechargeModel/fetchRechargeStatus',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CreditsService.fetchRechargeStatus(params)).unwrap();
		return data;
	}
);
const creditsMRechargeModel = slice({
	name: 'creditsMRechargeModel',
	initialState: <ICreditsPCRechargeState>{
		config: {},
		userId: ''
	},
	reducers: {},
	extraReducers: {
		[fetchCreditConfig.fulfilled.type]: (state, { payload }) => {
			state.config = payload;
		},
		[fetchVerifyCode.fulfilled.type]: (state, { payload }) => {
			state.userId = payload.userId;
		}
	},
	effect: { fetchCreditConfig, fetchVerifyCode, recharge, fetchRechargeStatus }
});

export default creditsMRechargeModel;

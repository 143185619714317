import { Middleware } from '@reduxjs/toolkit';
import isEqual from 'fast-deep-equal';
import { produce } from 'immer';
import get from 'lodash/get';
import set from 'lodash/set';

import { browser } from '$lib/utils/tools';
import {
	getLocalStore,
	getSessionStore,
	setLocalStore,
	setSessionStore
} from '$lib/utils/tools/storage';

import { HYDRATE } from './redux-wrapper';

type IPersistTree = {
	key: string;
	type: 'local' | 'session' | string;
};
type IStorageConfig = {
	persistTrees: IPersistTree[];
};
export const transformState = (
	persistTrees: IPersistTree[],
	transform: (persistTree: IPersistTree, currentState: any, newState: any) => any,
	state: any,
	newState?: any
) => {
	return produce(state, (draftState: any) => {
		for (const persistTree of persistTrees) {
			const result = transform(
				persistTree,
				get(state, persistTree.key),
				get(newState, persistTree.key)
			);
			if (typeof result !== 'undefined') {
				set(draftState, persistTree.key, result);
			}
		}
	});
};

export function storageMiddleware(config: IStorageConfig): Middleware {
	return (store) => {
		return (next) => (action) => {
			switch (action.type) {
				case HYDRATE:
					if (browser && config?.persistTrees?.length) {
						action.payload = transformState(
							config.persistTrees,
							({ key, type }) => {
								if (type === 'local') {
									return getLocalStore(key);
								} else {
									return getSessionStore(key);
								}
							},
							action.payload
						);
					}

				default: {
					const oldState = store.getState();
					const result = next(action);
					const newState = store.getState();
					if (config?.persistTrees?.length) {
						transformState(
							config?.persistTrees,
							({ key, type }, oldSubtreeState, newSubtreeState) => {
								if (!isEqual(oldSubtreeState, newSubtreeState)) {
									if (type === 'local') {
										setLocalStore(key, newSubtreeState);
									} else {
										setSessionStore(key, newSubtreeState);
									}
								}
							},
							oldState,
							newState
						);
					}
					return result;
				}
			}
		};
	};
}

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ActivitySummerShirtFreeService, IFetchTShirtClassicalPicRes, IPageParamsReq, IShippingAddressInfo } from '$lib/service/activity-summer-shirt-free';

import { ActivitySummerShirtFreeState } from './types';

/** 获取活动信息 */
export const fetchTShirtActivityConfig = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchTShirtActivityConfig',
	async (_, { dispatch }) => {
		const { data } = await dispatch(ActivitySummerShirtFreeService.fetchTShirtActivityConfig()).unwrap();
		return data;
	})

export const fetchMyInviteRecord = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchMyInviteRecord',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			ActivitySummerShirtFreeService.fetchMyInviteRecord()
		).unwrap();
		return data;
	}
);

/** 获取精选图片信息 */
export const fetchClasssicalPicList = createAsyncThunk<IFetchTShirtClassicalPicRes, IPageParamsReq>(
	'activitySummerShirtFreeModel/fetchClasssicalPicList',
	async (params, { dispatch }) => {
		const { data } = await dispatch(ActivitySummerShirtFreeService.fetchClasssicalPicList(params)).unwrap();
		return data;
	}
);

export const fetchTshirtExchangeRecordCarousel = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchTshirtExchangeRecordCarousel',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			ActivitySummerShirtFreeService.fetchTshirtExchangeRecordCarousel()
		).unwrap();
		return data?.exchangeRecords;
	}
);

export const fetchExchangeRecord = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchExchangeRecord',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			ActivitySummerShirtFreeService.fetchExchangeRecord()
		).unwrap();
		return data?.exchangeRecords;
	}
);


export const fetchMyPrize = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchMyPrize',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			ActivitySummerShirtFreeService.fetchMyPrize()
		).unwrap();
		return data?.prizeList;
	}
);

/** 确认订单相关接口 */
export const fetchGoodsDetail = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchGoodsDetail',
	async (params: {
		goodsCode: string;
		configId?: string;
		purchaseOrderNo?: string;
		userId?: string;
	}, { dispatch }) => {
		const { data } = await dispatch(
			ActivitySummerShirtFreeService.fetchGoodsDetail(params)
		).unwrap();
		return data;
	}
)
export const fetchSubmitOrder = createAsyncThunk(
	'activitySummerShirtFreeModel/fetchSubmitOrder',
	async (params, { dispatch }) => {
		const { data } = await dispatch(ActivitySummerShirtFreeService.fetchOrderSubmit(params)).unwrap();
		return data;
	}
)
export const fetchShippingAddress = createAsyncThunk(
	'ypStoreAddressModel/fetchShippingAddress',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(ActivitySummerShirtFreeService.fetchShippingAddress()).unwrap();
			return data;
		} catch (e) {
			rejectWithValue(e);
			return e;
		}
	}
);
export const submit = createAsyncThunk(
	'ypStoreAddressModel/submit',
	async (params: IShippingAddressInfo, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(
				ActivitySummerShirtFreeService[params.receiveAddrId ? 'updateShippingAddress' : 'postShippingAddress'](
					params
				)
			).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const ActivitySummerShirtFree = slice({
	name: 'activitySummerShirtFreeModel',
	initialState: <ActivitySummerShirtFreeState>{
		taskInfo: {
			qualification: 1,
			activityId: 0,
			activityStatus: 1,
			tshirtGoodsCode: ''
		},
		picInfoList: {
			buyerShowRecords: []
		},
		myInviteRecord: {
			inviteRecords: [],
			inviteConfig: { everyX: 1, maxN: 1 }
		},
		carouselUsers: [],
		prizeList: [],
		exchangeRecords: [],
	},
	reducers: {
		setState(state, { payload }) { }
	},
	extraReducers: {
		[fetchTShirtActivityConfig.fulfilled.type]: (state, { payload }) => {
			state.taskInfo = payload;
		},
		[fetchClasssicalPicList.fulfilled.type]: (state, { payload }) => {
			state.picInfoList = payload;
		},
		[fetchMyInviteRecord.fulfilled.type]: (state, { payload }) => {
			state.myInviteRecord = payload;
		},
		[fetchExchangeRecord.fulfilled.type]: (state, { payload }) => {
			state.exchangeRecords = payload;
		},
		[fetchMyPrize.fulfilled.type]: (state, { payload }) => {
			state.prizeList = payload;
		},
		[fetchTshirtExchangeRecordCarousel.fulfilled.type]: (state, { payload }) => {
			state.carouselUsers = payload;
		},
	},
	effect: {
		fetchTShirtActivityConfig,
		fetchClasssicalPicList,
		fetchMyInviteRecord,
		fetchExchangeRecord,
		fetchMyPrize,
		fetchTshirtExchangeRecordCarousel,
		fetchGoodsDetail,
		fetchSubmitOrder,
		fetchShippingAddress,
		submit
	},
});


export default ActivitySummerShirtFree;

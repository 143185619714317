import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ApplyReq, IGetCashoutRulesReq, WithdrawalService } from '$lib/service/withdrawal';

/** 获取余额信息 */
const getAccountMoney = createAsyncThunk(
	'withdrawalApplyModel/getAccountMoney',
	async (params: { active_id: string }, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getAccountMoney(params)).unwrap();
		return data;
	}
);

/** 获取提现规则 */
const getCashoutRules = createAsyncThunk(
	'withdrawalApplyModel/getCashoutRules',
	async (params: IGetCashoutRulesReq, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getCashoutRules(params)).unwrap();
		return data;
	}
);

/** 申请提现 */
const fetchApplyWithdrawal = createAsyncThunk(
	'withdrawalApplyModel/fetchApplyWithdrawal',
	async (params: ApplyReq, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(WithdrawalService.applyWithdrawal(params)).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const withdrawalApplyModel = slice({
	name: 'withdrawalApplyModel',
	initialState: {},
	reducers: {},
	effect: { getAccountMoney, getCashoutRules, fetchApplyWithdrawal },
	extraReducers: {}
});

export default withdrawalApplyModel;

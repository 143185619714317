import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { NewYearActivityService } from '$lib/service/new-year-activity';

import { NewYearActivityModelState } from './types';
/** 活动时间 */
export const fetchNewYearActivityConfig = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityConfig',
	async (_, { dispatch }) => {
		const { data } = await dispatch(NewYearActivityService.fetchNewYearActivityConfig()).unwrap();
		return data;
	}
);
/** 福气列表 */
export const fetchNewYearActivityMyPrizeList = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityMyPrizeList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			NewYearActivityService.fetchNewYearActivityMyPrizeList()
		).unwrap();
		return data;
	}
);
/** 中奖名单列表 */
export const fetchNewYearActivityWinPrizeList = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityWinPrizeList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			NewYearActivityService.fetchNewYearActivityWinPrizeList()
		).unwrap();
		return data;
	}
);
export const fetchNewYearActivityCarousel = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityCarousel',
	async (_, { dispatch }) => {
		const { data } = await dispatch(NewYearActivityService.fetchNewYearActivityCarousel()).unwrap();
		return data;
	}
);

export const fetchNewYearActivityMyFuka = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityMyFuka',
	async (_, { dispatch }) => {
		const { data } = await dispatch(NewYearActivityService.fetchNewYearActivityMyFuka()).unwrap();
		return data;
	}
);

export const fetchNewYearActivityDrawPrizeTimes = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityDrawPrizeTimes',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			NewYearActivityService.fetchNewYearActivityDrawPrizeTimes()
		).unwrap();
		return data;
	}
);

export const newYearActivityDrawPrize = createAsyncThunk(
	'newYearActivityModel/newYearActivityDrawPrize',
	async (_, { dispatch }) => {
		const { data } = await dispatch(NewYearActivityService.newYearActivityDrawPrize()).unwrap();
		return data;
	}
);

export const fetchNewYearActivityTasks = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityTasks',
	async (_, { dispatch }) => {
		const { data } = await dispatch(NewYearActivityService.fetchNewYearActivityTasks()).unwrap();
		return data;
	}
);

export const fetchNewYearActivityPrizeResult = createAsyncThunk(
	'newYearActivityModel/fetchNewYearActivityPrizeResult',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			NewYearActivityService.fetchNewYearActivityPrizeResult()
		).unwrap();
		return data;
	}
);

const newYearActivityModel = slice({
	name: 'newYearActivityModel',
	initialState: <NewYearActivityModelState>{
		config: {
			activityEndTime: 0,
			activityId: 0,
			activityPreparingStartTime: 0,
			activityStartTime: 0,
			activityTaskEndTime: 0,
			activityStatus: 'PREPARING',
			lotteryResultPublishTime: 0,
			activityName: '',
			activityDesc: ''
		},
		carousel: [],
		myFuka: {
			fukaList: [],
			fukaSetCount: 0,
			probability: 0
		},
		drawPrizeLeftCount: 0,
		taskListResult: {
			taskList: [],
			resumeExists: false
		},
		activityPrizeResult: {
			notifiedFlag: false,
			prizeName: '',
			prizeType: 'CASH'
		},
		myPrize: {
			prizeList: []
		},
		prizeResult: {
			prizeResultList: []
		},
		userData: {
			user_info: {
				user_id: '',
				uuid: '',
				tel: '',
				time: '',
				openid: '',
				nickname: '',
				status: '',
				avatar_check: 0,
				headimgurl: '',
				username_check: 0,
				has_pwd: 0,
				username: '',
				has_invite: 0,
				protection_time: 0
			},
			app_info: {
				appVersion: '',
				desc: '',
				update_type: 0,
				package_path: '',
				version: '',
				checking: 0,
				must: 0,
				active: 0
			},
			auth_info: {
				id_card_status: '',
				face_status: '',
				company_status: '',
				company_fail_reason: '',
				is_self_employed: 0,
				auth_fail_reason: '',
				company_name: '',
				sex: 0
			},
			integral_info: {
				pay_money: '',
				integral: 0,
				expend_integral: 0,
				temporary_integral: 0
			},
			member_vip: {
				vipType: 0,
				name: '',
				expireTime: '',
				level: 0,
				status: 0,
				remainDays: 0,
				todayRemainTimes: 0,
				vipSpu: 0
			}
		}
	},
	reducers: {
		setState(state, { payload }) {}
	},
	extraReducers: {
		[fetchNewYearActivityConfig.fulfilled.type]: (state, { payload }) => {
			state.config = payload;
		},
		[fetchNewYearActivityMyPrizeList.fulfilled.type]: (state, { payload }) => {
			state.myPrize = payload;
		},
		[fetchNewYearActivityWinPrizeList.fulfilled.type]: (state, { payload }) => {
			state.prizeResult = payload;
		},
		[fetchNewYearActivityCarousel.fulfilled.type]: (state, { payload }) => {
			state.carousel = payload?.records;
		},
		[fetchNewYearActivityMyFuka.fulfilled.type]: (state, { payload }) => {
			state.myFuka = payload;
		},
		[fetchNewYearActivityDrawPrizeTimes.fulfilled.type]: (state, { payload }) => {
			state.drawPrizeLeftCount = payload?.leftCount;
		},
		[fetchNewYearActivityTasks.fulfilled.type]: (state, { payload }) => {
			state.taskListResult = payload;
		},
		[fetchNewYearActivityPrizeResult.fulfilled.type]: (state, { payload }) => {
			state.activityPrizeResult = payload;
		}
	},
	effect: {
		fetchNewYearActivityConfig,
		fetchNewYearActivityCarousel,
		fetchNewYearActivityMyFuka,
		fetchNewYearActivityDrawPrizeTimes,
		newYearActivityDrawPrize,
		fetchNewYearActivityTasks,
		fetchNewYearActivityPrizeResult,
		fetchNewYearActivityWinPrizeList,
		fetchNewYearActivityMyPrizeList
	}
});

export default newYearActivityModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { FeedbackService, IFetchFeedbackConfigParams } from '$lib/service/feedback';
import { IAddFeedbackParams } from '$lib/service/feedback/types';

import { IFeedbackState } from './types';

export const fetchFeedbackConfig = createAsyncThunk(
	'feedbackModel/fetchFeedbackConfig',
	async (params: IFetchFeedbackConfigParams, { dispatch }) => {
		const { data } = await dispatch(FeedbackService.fetchFeedbackConfig(params)).unwrap();

		return data;
	}
);

export const addFeedback = createAsyncThunk(
	'feedbackModel/addFeedback',
	async (params: IAddFeedbackParams, { dispatch }) => {
		const { data } = await dispatch(FeedbackService.addFeedback(params)).unwrap();
		return data;
	}
);

const feedbackModel = slice({
	name: 'feedbackModel',
	initialState: {
		config: {}
	} as IFeedbackState,
	reducers: {
		setFeedbackConfig: (state, { payload }) => {
			state.config = payload;
		}
	},
	extraReducers: {
		[fetchFeedbackConfig.fulfilled.type]: (state, { payload }) => {
			state.config = payload;
		}
	},
	effect: {
		fetchFeedbackConfig,
		addFeedback
	}
});

export default feedbackModel;

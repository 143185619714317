import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IGetOccInfoParams,
	IIosBuyLimitParams,
	IMemberVipOrderParams,
	IMemberVipOrderRes,
	IMemberVipParams,
	IMemberVipPurchaseData,
	IMemberVipRenewData,
	IMemberVipRenewParams,
	IMemberVipUpgradeData,
	IMemberVipUpgradeParams,
	IOccInfosData,
	IOrderNoStatusRes,
	IPreOrderNo,
	IPurchaseResultRes,
	IQueryCouponData,
	IQueryCouponParams,
	IQueryHitGrayParams,
	IQueryHitGrayQueryData,
	IVoucherRetainData,
	IVoucherRetainParams,
	MemberVipService
} from '$lib/service/member-vip';
import { IUserIntegralInfo, YpStoreService } from '$lib/service/yp-store';
import BuryPoint from '$lib/utils/burypoint';

import { formatVipData } from '../utils';
import { IMemberVipDetailState } from './types';

/** 获取用户会员数据 */
const getIosRmbBuyLimitFlag = createAsyncThunk<any, IIosBuyLimitParams>(
	'memberVipDetailModel/getIosRmbBuyLimitFlag',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.getIosRmbBuyLimitFlag(params)).unwrap();
		return data;
	}
);

/** 获取用户会员数据 */
const vipMembership = createAsyncThunk<any, any>(
	'memberVipDetailModel/vipMembership',
	async ({ vipType, vipGroup, isNewPurchase, callBack }, { dispatch }) => {
		BuryPoint.collectEvent('InterfaceReturn', {
			interface_name: 'h5会员数据查询',
			isNewPurchase: isNewPurchase!,
			vipType,
			routerPath: window.location.href
		});
		const service = isNewPurchase
			? MemberVipService.vipMembershipV3
			: MemberVipService.vipMembership;
		const { data } = await dispatch(service({ vipType, vipGroup })).unwrap();
		callBack && callBack(data);
		return data;
	}
);
/** 会员购买 */
const vipPurchaseQuery = createAsyncThunk<IMemberVipPurchaseData, IMemberVipParams>(
	'memberVipDetailModel/purchaseQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { vipType, isAdd, callback, preVipPurchase, vipGroup, isNewPurchase } = params;
			/** 商品组编码：会员卡-GENERAL_VIP，次卡-TIMES_VIP	 */
			const goodsGroupCode = vipType == 1002 ? 'GENERAL_VIP' : 'TIMES_VIP';
			let data;
			BuryPoint.collectEvent('InterfaceReturn', {
				interface_name: '会员详情查询接口',
				isNewPurchase: isNewPurchase!,
				routerPath: window.location.href
			});
			// 购买接口替换，需要重新处理数据
			if (isNewPurchase) {
				const { data: PreData } = await dispatch(
					MemberVipService.newPurchaseQuery({ goodsGroupCode, vipGroup })
				).unwrap();
				data = formatVipData(PreData, goodsGroupCode);
			} else {
				const { data: preData } = await dispatch(
					MemberVipService.purchaseQuery({ vipType, vipGroup })
				).unwrap();
				data = preData;
			}
			// isAdd = true代表重新请求的会员次卡数据需要手动拼装到一起
			if (isAdd && preVipPurchase && preVipPurchase?.vipSpuList?.length > 0) {
				// 购买的老接口才需要组装此数据
				if (!isNewPurchase) {
					data.onceCardDelayWarn = data.delayWarn;
					data.delayWarn = preVipPurchase.delayWarn;
				}
				data.vipSpuList = preVipPurchase.vipSpuList.concat(data.vipSpuList);
			}
			callback && callback(data);
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员续费 */
const vipRenewQuery = createAsyncThunk<IMemberVipRenewData, IMemberVipRenewParams>(
	'memberVipDetailModel/renewQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.renewQuery(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员升级 */
const vipUpgradeQuery = createAsyncThunk<IMemberVipUpgradeData, IMemberVipUpgradeParams>(
	'memberVipDetailModel/fetchVipDetailQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.upgradeQuery(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 提交订单 */
const submit = createAsyncThunk<IMemberVipOrderRes, IMemberVipOrderParams>(
	'memberVipDetailModel/submit',
	async ({ isNewPurchase, ...params }, { dispatch, rejectWithValue }) => {
		try {
			BuryPoint.collectEvent('InterfaceReturn', {
				interface_name: 'h5提交订单接口查询',
				isNewPurchase: isNewPurchase!,
			});
			const service = isNewPurchase ? MemberVipService.createOrderV2 : MemberVipService.createOrder;
			const { data } = await dispatch(service(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/**
 * 获取支付结果
 */
const fetchPurchaseResult = createAsyncThunk<IPurchaseResultRes, string>(
	'ypStoreDetailModel/fetchPurchaseResult',
	async (tradeNo, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchPurchaseResult({ tradeNo })).unwrap();
		return data;
	}
);
/** 查询支付是否走工行 */
const fetchHitGray = createAsyncThunk<IQueryHitGrayQueryData, IQueryHitGrayParams>(
	'memberVipDetailModel/fetchHitGray',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.queryHitGray(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 获取用户积分 */
const fetchUserIntegral = createAsyncThunk<IUserIntegralInfo>(
	'memberVipDetailModel/getIntegral',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.fetchUserIntegral()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 根据id查询工种详情 */
const getOccInfos = createAsyncThunk<IOccInfosData, IGetOccInfoParams>(
	'memberVipDetailModel/getOccInfos',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.getOccInfos(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 获取预下单号（开启工行支付时才调用） */
const fetchPreOrderNo = createAsyncThunk<IPreOrderNo>(
	'memberVipDetailModel/fetchPreOrderNo',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.fetchPreOrderNo()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 查询订单结果 */
const fetchByOrderNo = createAsyncThunk<IOrderNoStatusRes, { orderNo: string }>(
	'memberVipDetailModel/fetchByOrderNo',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.fetchByOrderNo(params)).unwrap();
		return data;
	}
);
/** 卡券列表 */
const queryCoupon = createAsyncThunk<IQueryCouponData, IQueryCouponParams>(
	'memberVipDetailModel/queryCoupon',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.queryCoupon(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员弹窗优惠券挽留 */
const voucherRetain = createAsyncThunk<IVoucherRetainData, IVoucherRetainParams>(
	'memberVipDetailModel/voucherRetain',
	async (params, { dispatch }) => {
		try {
			const { data } = await dispatch(MemberVipService.voucherRetain(params)).unwrap();
			return data;
		} catch (e: any) {
			return e;
		}
	}
);
const INIT_STATE = {
	/** 会员信息 */
	memberInfo: {
		uid: 0,
		name: '',
		vipRightList: [],
		supportVipTypeList: []
	},
	/** 会员详情(统一格式化成一个结构处理数据) */
	vipDetail: {
		delayWarn: false,
		vipSpuList: []
	},
	vipTypeVal: 1002,
	/** 订单信息 */
	orderInfo: {
		payChannel: 'WEIXIN',
		payType: 'ONLINE_PAY',
		vipType: '',
		vipSpu: '',
		type: 0,
		showIntegralChannel: false
	},
	/** 积分信息 */
	userIntegral: {},
	/** 卡券列表 */
	couponList: [],
	/** 交易开关 */
	trade: {
		limitFlag: false,
		popupFlag: ''
	}
};
const memberVipDetailModel = slice({
	name: 'memberVipDetailModel',
	initialState: INIT_STATE as IMemberVipDetailState,
	reducers: {
		initState() {
			return INIT_STATE as IMemberVipDetailState;
		},
		setState(state, { payload }) {
			return { ...state, ...payload } as IMemberVipDetailState;
		}
	},
	extraReducers: {
		[vipMembership.fulfilled.type]: (state, { payload }) => {
			state.memberInfo = payload;
		},
		[getIosRmbBuyLimitFlag.fulfilled.type]: (state, { payload }) => {
			state.trade = payload;
		},
		[vipPurchaseQuery.fulfilled.type]: (state, { payload }) => {
			state.vipDetail = payload;
		},
		[vipRenewQuery.fulfilled.type]: (state, { payload }) => {
			// 格式化统一结构
			const { delayWarn, ...ext } = payload;
			state.vipDetail = {
				vipSpuList: [ext],
				delayWarn
			};
		},
		[vipUpgradeQuery.fulfilled.type]: (state, { payload }) => {
			const { delayWarn, price, integralPrice, upgradeToVipSku, upgradeFromVipSku, ...ext } =
				payload;
			const { vipSku, name } = payload.upgradeToVipSku;
			/** 过滤数据 */
			ext.vipSkuList = [
				{
					discountPrice: price,
					integralDiscountPrice: integralPrice,
					vipSku,
					name,
					integralPrice,
					price
				}
			];
			state.vipDetail = {
				vipSpuList: [ext],
				upgradeToVipSku,
				upgradeFromVipSku,
				delayWarn
			};
		},
		[fetchUserIntegral.fulfilled.type]: (state, { payload }) => {
			state.userIntegral = payload;
		},
		[queryCoupon.fulfilled.type]: (state, { payload }) => {
			state.couponList = payload?.voucherList || [];
		}
	},
	effect: {
		vipMembership,
		vipPurchaseQuery,
		submit,
		fetchPurchaseResult,
		vipRenewQuery,
		fetchHitGray,
		vipUpgradeQuery,
		fetchUserIntegral,
		getOccInfos,
		fetchPreOrderNo,
		fetchByOrderNo,
		queryCoupon,
		voucherRetain,
		getIosRmbBuyLimitFlag
	}
});

export default memberVipDetailModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ReportCentreService } from '$lib/service/report-centre';

const list = createAsyncThunk(
	'reportCentreInfoListModel/list',
	async (_, { dispatch, getState }) => {
		try {
			const {
				reportCentreInfoListModel: { curPage }
			} = <any>getState();
			const { data } = await dispatch(
				ReportCentreService.contactRecords({ currentPage: curPage, pageSize: 10 })
			).unwrap();
			return data;
		} catch (e) {
			return {};
		}
	}
);

const reportCentreInfoListModel = slice({
	name: 'reportCentreInfoListModel',
	initialState: {
		list: [],
		detailInfo: {},
		hasMore: true,
		curPage: 1
	},
	reducers: {
		reset(state) {
			state.list = [];
			state.detailInfo = {};
		},
		resetPage(state) {
			state.hasMore = true;
			state.curPage = 1;
		},
		setDetail(state, { payload }) {
			state.detailInfo = { ...payload };
		}
	},
	extraReducers: {
		[list.fulfilled.type]: (state, { payload }) => {
			if (payload?.data?.length === 0) {
				state.hasMore = false;
			}
			if (state.curPage === 1) {
				state.list = payload.data;
			} else {
				state.list = state.list.concat(payload.data);
			}
			++state.curPage;
		}
	},
	effect: { list }
});

export default reportCentreInfoListModel;

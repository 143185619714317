import { slice } from '$lib/model/utils';

const ypStoreAddressModel = slice({
	name: 'ypStoreLoginModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: {}
});

export default ypStoreAddressModel;

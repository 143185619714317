import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ComplainService } from '$lib/service/complain';
import { IFetchComplainDetailReq, IFetchComplainHistoryReq } from '$lib/service/complain/types';

export const fetchComplainHistory = createAsyncThunk(
	'complainHistoryModel/fetchComplainHistory',
	async (params: IFetchComplainHistoryReq, { dispatch }) => {
		const { data } = await dispatch(ComplainService.fetchComplainHistory(params)).unwrap();
		return data;
	}
);

export const fetchComplainDetails = createAsyncThunk(
	'complainHistoryModel/fetchComplainDetails',
	async (params: IFetchComplainDetailReq, { dispatch }) => {
		const { data } = await dispatch(ComplainService.fetchComplainDetails(params)).unwrap();
		return data;
	}
);

const complainHistoryModel = slice({
	name: 'complainHistoryModel',
	initialState: {
		historyList: [],
		hasMore: false,
		params: {
			currentPage: 1,
			pageSize: 20,
			/** 到岗理赔申诉服务 固定*/
			projectId: 1001
		}
	},
	reducers: {
		setInitList: (state) => {
			state.historyList = [];
		}
	},
	extraReducers: {
		[fetchComplainHistory.fulfilled.type]: (state, { payload }) => {
			state.historyList = !state.historyList?.length
				? payload?.data
				: state.historyList.concat(payload?.data);
			state.params = { ...state.params, currentPage: state?.params?.currentPage + 1 };
			state.hasMore = payload?.data?.length < 20 ? false : true;
		}
	},
	effect: {
		fetchComplainHistory,
		fetchComplainDetails
	}
});

export default complainHistoryModel;

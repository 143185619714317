import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ISecurityArticleReq } from '$lib/service/safe-center';
import SafeCenterService from '$lib/service/safe-center/SafeCenterService';
import { ISecurityArticleRes, IUserCenterRes } from '$lib/service/safe-center/types';

import { ISafeCenterState } from './types';

export const fetchSecurityArticle = createAsyncThunk<
	{
		type: number;
		list: ISecurityArticleRes['list'];
	},
	ISecurityArticleReq
>('safeCenterModel/fetchSecurityArticle', async (params, { dispatch }) => {
	const { data } = await dispatch(SafeCenterService.fetchSecurityArticle(params)).unwrap();
	return {
		type: params?.business,
		list: data?.list
	};
});

export const fetchUserData = createAsyncThunk<IUserCenterRes>(
	'safeCenterModel/fetchUserData',
	async (_, { dispatch }) => {
		try {
			const { data } = await dispatch(SafeCenterService.fetchUserData()).unwrap();
			return data;
		} catch {
			return {
				userBaseObj: {},
				userRealNameObj: {},
				userEnterpriseObj: {},
				userIntegralObj: {},
				userBizAggObj: {},
			} as unknown as IUserCenterRes;
		}
	}
);

const safeCenterModel = slice({
	name: 'safeCenterModel',
	initialState: {
		illegalList: [],
		insuranceList: [],
		guideList: [],
		member: {
			userBaseObj: {},
			userRealNameObj: {},
			userEnterpriseObj: {},
			userIntegralObj: {},
			userBizAggObj: {},
		}
	} as unknown as ISafeCenterState,
	reducers: {},
	extraReducers: {
		[fetchSecurityArticle.fulfilled.type]: (state, { payload }) => {
			if (payload.type === 3) {
				state.illegalList = payload.list;
			} else if (payload.type === 2) {
				state.insuranceList = payload.list;
			} else if (payload.type === 1) {
				state.guideList = payload.list;
			}
		},
		[fetchUserData.fulfilled.type]: (state, { payload }) => {
			state.member = payload;
		}
	},
	effect: {
		fetchSecurityArticle,
		fetchUserData
	}
});

export default safeCenterModel;

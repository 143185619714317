import { createAsyncThunk } from '@reduxjs/toolkit';

import { toast } from '$components/shared/toast';
import { slice } from '$lib/model/utils';
import {
	IFetchSecondHandManageParams,
	ISecondHandDetail,
	ISecondHandManageData,
	IUpdateSecondHandStatusParams,
	SecondHandService
} from '$lib/service/second-hand';

import { ISecondHandManageState } from './types';

const fetchList = createAsyncThunk(
	'secondHandManageModel/fetchList',
	async (params: Partial<IFetchSecondHandManageParams> | undefined, { getState, dispatch }) => {
		const {
			secondHandManageModel: { requestParams }
		} = <{ secondHandManageModel: ISecondHandManageState }>getState();
		dispatch(
			secondHandManageModel.actions.setParams({ ...params, pageNum: requestParams.pageNum })
		);
		const tParams = params ? { ...requestParams, ...params } : requestParams;
		const { data } = await dispatch(SecondHandService.fetchManageList(tParams)).unwrap();
		return { params: tParams, data };
	}
);

const updateStatus = createAsyncThunk(
	'secondHandManageModel/updateStatus',
	async (params: IUpdateSecondHandStatusParams, { dispatch }) => {
		try {
			await dispatch(SecondHandService.updateStatus(params)).unwrap();
			return params;
		} catch (e: any) {
			if (e?.message) {
				toast.show(e?.message);
			}
		}
	}
);
const secondHandManageModel = slice({
	name: 'secondHandManageModel',
	initialState: <ISecondHandManageState>{
		list: [],
		requestParams: {
			pageNum: 1,
			pageSize: 15
		},
		hasMore: false
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload } as ISecondHandManageState;
		},
		initParams: (state) => {
			state.requestParams = { pageNum: 1, pageSize: 15, auditStatus: '', endStatus: '' };
			state.list = [];
			state.hasMore = false;
		},
		setParams: (state, { payload }) => {
			state.requestParams = { ...state.requestParams, ...payload };
		}
	},
	extraReducers: {
		[fetchList.fulfilled.type]: (
			state,
			{
				payload
			}: { payload: { data: ISecondHandManageData; params: IFetchSecondHandManageParams } }
		) => {
			const { data, params } = payload;
			if (params.pageNum === 1) {
				state.list = data?.data ?? [];
			} else {
				state.list = data?.data ? state.list.concat(data.data) : state.list;
			}
			state.requestParams = { ...state.requestParams, ...params };
			state.hasMore = !!data?.data?.length;
		},
		[updateStatus.fulfilled.type]: (state, { payload }) => {
			const tList = state.list.slice(0);
			const currentIndex = tList.findIndex((each: ISecondHandDetail) => each.id == payload.id);
			if (!state.requestParams.auditStatus && !state.requestParams.endStatus) {
				tList[currentIndex].endStatus = payload.endStatus;
			} else {
				tList.splice(currentIndex, 1);
			}
			state.list = tList;
		}
	},
	effect: { fetchList, updateStatus }
});

export default secondHandManageModel;

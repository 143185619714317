import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ArticleService } from '$lib/service/article';
import { IFetchOuterArticleReq } from '$lib/service/article/types';

import { IOutArticleState } from './types';

export const fetchOuterArticle = createAsyncThunk(
	'outArticleModel/fetchOuterArticle',
	async (params: IFetchOuterArticleReq, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(ArticleService.fetchOuterArticle(params)).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

export const praiseArticle = createAsyncThunk(
	'outArticleModel/praiseArticle',
	async (params: { id: number | string }, { dispatch }) => {
		await dispatch(ArticleService.praiseArticle(params)).unwrap();
		return params.id;
	}
);

const outArticleModel = slice({
	name: 'outArticleModel',
	initialState: {
		praiseList: [],
		outArticleInfo: {}
	} as IOutArticleState,
	reducers: {
		setoutArticleInfo: (state, { payload }) => {
			state.outArticleInfo = payload;
		}
	},
	extraReducers: {
		[fetchOuterArticle.fulfilled.type]: (state, { payload }) => {
			state.outArticleInfo = payload;
		},
		[praiseArticle.fulfilled.type]: (state) => {
			state.praiseList = [...state.praiseList, state?.outArticleInfo?.articleId ?? ''];
			state.outArticleInfo.likeCount = (state?.outArticleInfo?.likeCount ?? 0) + 1;
		}
	},
	effect: {
		fetchOuterArticle,
		praiseArticle
	}
});

export const subtrees = ['outArticleModel.praiseList'];
export default outArticleModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IDepositConfig, IDepositStatus } from '$lib/service/deposit';

import { DEFAULT_CONFIG } from './constants';
import { IDepositState } from './types';

const fetchConfig = createAsyncThunk<IDepositConfig>(
	'depositModel/fetchConfig',
	async (_, { dispatch }) => {
		// const { data } = await dispatch(DepositService.fetchConfig({ type: 1000 })).unwrap();
		return {} as any;
	}
);

const fetchStatus = createAsyncThunk<IDepositStatus>(
	'depositModel/fetchStatus',
	async (_, { dispatch }) => {
		return {} as any
	}
);

const depositModel = slice({
	name: 'depositModel',
	initialState: <IDepositState>{
		config: DEFAULT_CONFIG,
		status: 0
	},
	reducers: {},
	extraReducers: {
		[fetchConfig.fulfilled.type]: (state, { payload }) => {
			state.config = payload;
		},
		[fetchStatus.fulfilled.type]: (state, { payload }) => {
			state.status = payload.status;
		}
	},
	effect: { fetchConfig, fetchStatus }
});

export default depositModel;

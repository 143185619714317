import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import loading from '$components/shared/loading/LoadingUtils';
import { toast } from '$components/shared/toast';
import { slice } from '$lib/model/utils';
import { actions } from '$lib/model/wrapper/turntable';
import turntableServices from '$lib/service/turntable';

import { TypedState } from './type';

const queryContent = createAsyncThunk('turntableModel/queryContent', async (_, { dispatch }) => {
	const response = await dispatch(turntableServices.query()).unwrap();
	return response.data;
});

const startDraw = createAsyncThunk(
	'turntableModel/startDraw',
	async (params: SStartDrawPayload, { dispatch }) => {
		const response = await dispatch(turntableServices.startDraw(params)).unwrap();
		dispatch(actions.turntableModel.queryContent());
		return response;
	}
);

const queryDrawTimes = createAsyncThunk(
	'turntableModel/queryDrawTimes',
	async (options: BasePayload, { dispatch }) => {
		const response = await dispatch(turntableServices.getTimes(options)).unwrap();
		if (!response.error) {
			console.warn(response);
		}
		console.error(response);
	}
);

const queryShareEnd = createAsyncThunk(
	'turntableModel/queryShareEnd',
	async (params: SQuerySharedPayload, { dispatch }) => {
		const response = await dispatch(turntableServices.shared(params)).unwrap();
		return response;
	}
);

const queryVideo = createAsyncThunk(
	'turntableModel/queryVideo',
	async (options: SQueryVideoPayload, { dispatch, rejectWithValue }) => {
		loading.show();
		const { data } = await dispatch(turntableServices.queryVideo(options)).unwrap();
		if (!data.video) {
			toast.show('广告还没准备好');
			return rejectWithValue(data);
		}
		return data;
	}
);

const queryVideoEnd = createAsyncThunk(
	'turntableModel/queryVideoEnd',
	async (params: SQueryVideoIsEndPayload, { dispatch }) => {
		const response = await dispatch(turntableServices.viewVideoEnd(params)).unwrap();
		return response;
	}
);

const shouldShowAdvDialog = createAsyncThunk(
	'turntableModel/shouldShowAdvDialog',
	async ({ callback, ...rest }: SQueryAdvDialogTimes, { dispatch }) => {
		const response = await dispatch(turntableServices.queryAdvDialogTimes(rest)).unwrap();
		if (!response.error) {
			return response;
		}
		console.error(response);
	}
);

const queryIsEnd = createAsyncThunk('turntableModel/queryIsEnd', async (_, { dispatch }) => {
	const response = await dispatch(turntableServices.queryIsEnd()).unwrap();
	return response;
});

const turntableModel = slice({
	name: 'turntableModel',
	initialState: <TypedState>{
		data: {}
	},
	reducers: {
		changeState(state, action: PayloadAction<Partial<typeof state>>) {
			return { ...state, ...action.payload };
		}
	},
	extraReducers: {
		[queryContent.fulfilled.type]: function (state, { payload }) {
			loading.hide();
			return { ...state, data: payload };
		},
		[queryContent.rejected.type]: function () {
			loading.hide();
		},
		[queryVideoEnd.rejected.type]: function () {
			loading.hide();
		},
		[queryShareEnd.rejected.type]: function () {
			loading.hide();
		},
		[queryVideo.rejected.type]: function () {
			loading.hide();
		},
		[startDraw.rejected.type]: function () {
			loading.hide();
		}
	},
	effect: {
		queryContent,
		startDraw,
		queryDrawTimes,
		queryShareEnd,
		queryVideo,
		queryVideoEnd,
		shouldShowAdvDialog,
		queryIsEnd
	}
});

/** 需要存放至cookie时,导出key值（固定写法） */
export default turntableModel;

/* eslint-disable */
// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit';
import { wrapMakeStore, nextReduxCookieMiddleware } from 'next-redux-cookie-wrapper';
import { storageMiddleware } from '../storageMiddleware';
import { createWrapper } from '../redux-wrapper';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

import configModel from '$lib/model/config';
import userModel from '$lib/model/user';
import appointActivityModel from '$pages/yp-store/appoint-activity/model';
import ypStoreDetailModel from '$pages/yp-store/detail/model';
import ypStoreGroupBookingManageModel from '$pages/yp-store/group-booking-manage/model';
import groupBookingOrderDetailModel from '$pages/yp-store/group-booking-order-detail/model';
import ypStoreIndexModel from '$pages/yp-store/index/model';
import ypStoreOrderModel from '$pages/yp-store/order/model';
import ypStoreAddressModel from '$pages/yp-store/shipping-address/model';
const makeStore = wrapMakeStore(() =>
	configureStore({
		reducer: { 
[configModel.name]: configModel.reducer,
[userModel.name]: userModel.reducer,
[appointActivityModel.name]: appointActivityModel.reducer,
[ypStoreDetailModel.name]: ypStoreDetailModel.reducer,
[ypStoreGroupBookingManageModel.name]: ypStoreGroupBookingManageModel.reducer,
[groupBookingOrderDetailModel.name]: groupBookingOrderDetailModel.reducer,
[ypStoreIndexModel.name]: ypStoreIndexModel.reducer,
[ypStoreOrderModel.name]: ypStoreOrderModel.reducer,
[ypStoreAddressModel.name]: ypStoreAddressModel.reducer, 
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().prepend(
				nextReduxCookieMiddleware({
					subtrees: ["configModel.headers","configModel.query",{"subtree":"configModel.platform","compress":false},{"subtree":"userModel.token","compress":false},{"subtree":"userModel.openId","compress":false}]
				}),
				storageMiddleware({
					persistTrees: []
				})
			)
	})
);

export const actions = {
[configModel.name]: configModel.actions,
[userModel.name]: userModel.actions,
[appointActivityModel.name]: appointActivityModel.actions,
[ypStoreDetailModel.name]: ypStoreDetailModel.actions,
[ypStoreGroupBookingManageModel.name]: ypStoreGroupBookingManageModel.actions,
[groupBookingOrderDetailModel.name]: groupBookingOrderDetailModel.actions,
[ypStoreIndexModel.name]: ypStoreIndexModel.actions,
[ypStoreOrderModel.name]: ypStoreOrderModel.actions,
[ypStoreAddressModel.name]: ypStoreAddressModel.actions,
}

export type AppState = ReturnType<AppStore['getState']>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type IReduxContext = {
	'@@observable': () => any;
	dispatch: AppDispatch;
	getState: () => AppState;
	liftedStore: ReturnType<Window['getReduxContext']>;
	replaceReducer: (r: any) => any;
	subscribe: (listener: (...r: any[]) => any) => any;
};
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

const wrapper = createWrapper<AppStore>(makeStore, undefined);

export { wrapper }
export default wrapper
/* eslint-disable */
// @ts-nocheck
import { configureStore } from '@reduxjs/toolkit';
import { wrapMakeStore, nextReduxCookieMiddleware } from 'next-redux-cookie-wrapper';
import { storageMiddleware } from '../storageMiddleware';
import { createWrapper } from '../redux-wrapper';
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux';

import configModel from '$lib/model/config';
import userModel from '$lib/model/user';
import complainModel from '$pages/deposit/complain/add/model';
import complainDetailModel from '$pages/deposit/complain/details/model';
import complainHistoryModel from '$pages/deposit/complain/history/model';
import invitationRecordModel from '$pages/deposit/invitation-record/model';
import depositModel from '$pages/deposit/model';
import signInModel from '$pages/deposit/sign-in/model';
import verifyCodeModel from '$pages/deposit/verify-code/model';
import withdrawApply from '$pages/deposit/withdraw/apply/model';
import withdrawDetailsModel from '$pages/deposit/withdraw/details/model';
import withdrawListModel from '$pages/deposit/withdraw/list/model';
const makeStore = wrapMakeStore(() =>
	configureStore({
		reducer: { 
[configModel.name]: configModel.reducer,
[userModel.name]: userModel.reducer,
[complainModel.name]: complainModel.reducer,
[complainDetailModel.name]: complainDetailModel.reducer,
[complainHistoryModel.name]: complainHistoryModel.reducer,
[invitationRecordModel.name]: invitationRecordModel.reducer,
[depositModel.name]: depositModel.reducer,
[signInModel.name]: signInModel.reducer,
[verifyCodeModel.name]: verifyCodeModel.reducer,
[withdrawApply.name]: withdrawApply.reducer,
[withdrawDetailsModel.name]: withdrawDetailsModel.reducer,
[withdrawListModel.name]: withdrawListModel.reducer, 
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().prepend(
				nextReduxCookieMiddleware({
					subtrees: ["configModel.headers","configModel.query",{"subtree":"configModel.platform","compress":false},{"subtree":"userModel.token","compress":false},{"subtree":"userModel.openId","compress":false}]
				}),
				storageMiddleware({
					persistTrees: []
				})
			)
	})
);

export const actions = {
[configModel.name]: configModel.actions,
[userModel.name]: userModel.actions,
[complainModel.name]: complainModel.actions,
[complainDetailModel.name]: complainDetailModel.actions,
[complainHistoryModel.name]: complainHistoryModel.actions,
[invitationRecordModel.name]: invitationRecordModel.actions,
[depositModel.name]: depositModel.actions,
[signInModel.name]: signInModel.actions,
[verifyCodeModel.name]: verifyCodeModel.actions,
[withdrawApply.name]: withdrawApply.actions,
[withdrawDetailsModel.name]: withdrawDetailsModel.actions,
[withdrawListModel.name]: withdrawListModel.actions,
}

export type AppState = ReturnType<AppStore['getState']>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];
export type IReduxContext = {
	'@@observable': () => any;
	dispatch: AppDispatch;
	getState: () => AppState;
	liftedStore: ReturnType<Window['getReduxContext']>;
	replaceReducer: (r: any) => any;
	subscribe: (listener: (...r: any[]) => any) => any;
};
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

const wrapper = createWrapper<AppStore>(makeStore, undefined);

export { wrapper }
export default wrapper
import { createAsyncThunk } from '@reduxjs/toolkit';

import { postApi } from '$lib/model/request';
import { slice } from '$lib/model/utils';

import { Activities } from '../utils';

const queryActivities = createAsyncThunk(
	'festivalActivityModel/queryActivities',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			postApi('/marketingcenter/v1/newYearActivity/activities') as any
		).unwrap();

		return { data };
	}
);

const fetchActivityStatus = createAsyncThunk(
	'festivalActivityModel/fetchActivityStatus',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			postApi('/marketingcenter/v1/newYearActivity/activitiesHasEnd') as any
		).unwrap();
		return data;
	}
);

const fetchItemStatus = createAsyncThunk(
	'festivalActivityModel/fetchItemStatus',
	async ({ code }: { code: string }, { dispatch }) => {
		const { data } = await dispatch(
			postApi('/marketingcenter/v1/newYearActivity/activities') as any
		).unwrap();
		if (data.length) {
			const item = data.find((each: any) => each.code === code);
			return item.status;
		} else return 4;
	}
);

const fetchUserImg = createAsyncThunk(
	'fetchUserImg',
	async (params: { uid: string; encrypt?: string }, { dispatch }) => {
		/** 获取用户头像 */
		const { data } = await dispatch(
			postApi('/user/avatar/getOneAvatar', { data: params, php: true })
		).unwrap();
		return data;
	}
);

const festivalActivityModel = slice({
	name: 'festivalActivityModel',
	initialState: {
		activities: [],
		status: 0
	} as {
		activities: Activities[];
		/** 活动状态生效中 - 0未开启 - 1已结束 - 2异常- 3 */
		status: string | number;
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload };
		}
	},
	extraReducers: {
		[queryActivities.fulfilled.type]: (state, { payload }) => {
			state.activities = payload.data;

			return state;
		}
	},
	effect: {
		queryActivities,
		fetchUserImg,
		fetchActivityStatus,
		fetchItemStatus
	}
});

export default festivalActivityModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { actions } from '$lib/model/wrapper/secondHand';
import { IUpdateSecondHandStatusParams, SecondHandService } from '$lib/service/second-hand';

import { ISecondHandDetailState } from './types';

const fetchDetail = createAsyncThunk(
	'secondHandDetailModel/fetchDetail',
	async (params: { id: string | number; userId?: string | number }, { dispatch }) => {
		const { data } = await dispatch(SecondHandService.fetchDetail(params)).unwrap();
		return data;
	}
);

const updateStatus = createAsyncThunk(
	'secondHandDetailModel/updateStatus',
	async (params: IUpdateSecondHandStatusParams, { dispatch }) => {
		await dispatch(actions.secondHandManageModel.updateStatus(params)).unwrap();
		return params;
	}
);

const fetchPhone = createAsyncThunk(
	'secondHandDetailModel/fetchPhone',
	/** 原生请求时，不显示错误信息 */
	async (params: { id: number | string; native?: boolean }, { dispatch }) => {
		const { data } = await dispatch(
			SecondHandService.fetchPhoneNumber(params, { hideInterceptMessage: !!params?.native })
		).unwrap();
		return data;
	}
);

const secondHandDetailModel = slice({
	name: 'secondHandDetailModel',
	initialState: <ISecondHandDetailState>{
		info: {}
	},
	reducers: {
		setInfo(state, { payload }) {
			state.info = payload;
		}
	},
	extraReducers: {
		[fetchDetail.fulfilled.type]: (state, { payload }) => {
			state.info = payload;
		},
		[updateStatus.fulfilled.type]: (state, { payload }) => {
			state.info.endStatus = payload.endStatus;
		}
	},
	effect: { fetchDetail, updateStatus, fetchPhone }
});

export default secondHandDetailModel;

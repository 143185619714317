import {
	GetServerSideProps,
	GetServerSidePropsContext,
	GetStaticProps,
	GetStaticPropsContext,
	NextComponentType,
	NextPageContext
} from 'next';
import { AppContext, AppInitialProps } from 'next/app';
import React, { useEffect, useMemo, useRef } from 'react';
import { Store } from 'redux';

export const HYDRATE = '__NEXT_REDUX_WRAPPER_HYDRATE__';

const getIsServer = () => typeof window === 'undefined';
const useEnhancedEffect = !getIsServer() ? React.useLayoutEffect : React.useEffect;

const getDeserializedState = <S extends Store>(
	initialState: any,
	{ deserializeState }: Config<S> = {}
) => (deserializeState ? deserializeState(initialState) : initialState);

const getSerializedState = <S extends Store>(state: any, { serializeState }: Config<S> = {}) =>
	serializeState ? serializeState(state) : state;

export declare type MakeStore<S extends Store> = (context: Context) => S;

export interface InitStoreOptions<S extends Store> {
	makeStore: MakeStore<S>;
	context?: Context;
}

let sharedClientStore: any;

const initStore = <S extends Store>({ makeStore, context = {} }: InitStoreOptions<S>): S => {
	const createStore = () => makeStore(context);

	if (getIsServer()) {
		const req: any = (context as AppContext['ctx'])?.req || (context as AppContext)?.ctx?.req;
		if (req) {
			if (!req.__nextReduxWrapperStore) {
				req.__nextReduxWrapperStore = createStore();
			}
			return req.__nextReduxWrapperStore;
		}

		return createStore();
	}

	if (!sharedClientStore) {
		sharedClientStore = createStore();
	}

	return sharedClientStore;
};

export const createWrapper = <S extends Store>(makeStore: MakeStore<S>, config: Config<S> = {}) => {
	const makeProps = async ({
		callback,
		context,
		addStoreToContext = false
	}: {
		callback: Callback<S, any>;
		context: any;
		addStoreToContext?: boolean;
	}): Promise<WrapperProps> => {
		const store = initStore({ context, makeStore });

		if (addStoreToContext) {
			if (context.ctx) {
				context.ctx.store = store;
			} else {
				context.store = store;
			}
		}

		const nextCallback = callback && callback(store);
		const initialProps = (nextCallback && (await nextCallback(context))) || {};

		const state = store.getState();

		return {
			initialProps,
			initialState: getIsServer() ? getSerializedState<S>(state, config) : state
		};
	};

	const getInitialPageProps = <P extends {} = any>(
		callback: PageCallback<S, P>
	): GetInitialPageProps<P> => async (context: NextPageContext | any) => {
		if ('getState' in context) {
			return callback && callback(context as any);
		}
		return makeProps({ callback, context, addStoreToContext: true });
	};

	const getInitialAppProps = <P extends {} = any>(
		callback: AppCallback<S, P>
	): GetInitialAppProps<P> => async (context: AppContext) => {
		const { initialProps, initialState } = await makeProps({
			callback,
			context,
			addStoreToContext: true
		});
		return {
			...initialProps,
			initialState
		};
	};

	const getStaticProps = <P extends {} = any>(
		callback: GetStaticPropsCallback<S, P>
	): GetStaticProps<P> => async (context) => {
		const { initialProps, initialState } = await makeProps({ callback, context });
		return {
			...initialProps,
			props: {
				...initialProps.props,
				initialState
			}
		} as any;
	};

	const getServerSideProps = <P extends {} = any>(
		callback: GetServerSidePropsCallback<S, P>
	): GetServerSideProps<P> => async (context) => await getStaticProps(callback as any)(context); // just not to repeat myself

	const hydrate = (store: S, state: any) => {
		if (!state) {
			return;
		}
		store.dispatch({
			type: HYDRATE,
			payload: getDeserializedState<S>(state, config)
		} as any);
	};

	const useHybridHydrate = (store: S, state: any) => {
		const firstRender = useRef<boolean>(true);

		useEffect(() => {
			firstRender.current = false;
		}, []);

		useMemo(() => {
			if (getIsServer() || firstRender.current) {
				hydrate(store, state);
			}
		}, [store, state]);

		useEnhancedEffect(() => {
			if (!getIsServer()) {
				hydrate(store, state);
			}
		}, [store, state]);
	};

	const useWrappedStore = ({
		initialState,
		initialProps,
		...props
	}: any): { store: any; props: any } => {
		const initialStateFromGSPorGSSR = props?.pageProps?.initialState;

		const store = useMemo(() => initStore({ makeStore, context: {} }), []);

		useHybridHydrate(store, initialState);
		useHybridHydrate(store, initialStateFromGSPorGSSR);

		let resultProps: any = props;
		if (initialProps && initialProps.pageProps) {
			resultProps.pageProps = {
				...initialProps.pageProps,
				...props.pageProps
			};
		}
		if (initialStateFromGSPorGSSR) {
			resultProps = { ...props, pageProps: { ...props.pageProps } };
			delete resultProps.pageProps.initialState;
		}

		if (resultProps?.pageProps?.initialProps) {
			resultProps.pageProps = { ...resultProps.pageProps, ...resultProps.pageProps.initialProps };
			delete resultProps.pageProps.initialProps;
		}
		resultProps.initialState = props.pageProps.initialState;
		return { store, props: { ...initialProps, ...resultProps } };
	};

	return {
		getServerSideProps,
		getStaticProps,
		getInitialAppProps,
		getInitialPageProps,
		useWrappedStore
	};
};

export type Context =
	| NextPageContext
	| AppContext
	| GetStaticPropsContext
	| GetServerSidePropsContext;

export interface Config<S extends Store> {
	serializeState?: (state: ReturnType<S['getState']>) => any;
	deserializeState?: (state: any) => ReturnType<S['getState']>;
	debug?: boolean;
}

export interface WrapperProps {
	initialProps: any;
	initialState: any;
}

type GetInitialPageProps<P> = NextComponentType<NextPageContext, any, P>['getInitialProps'];

type GetInitialAppProps<P> = ({
	Component,
	ctx
}: AppContext) => Promise<AppInitialProps & { pageProps: P }>;

export type GetStaticPropsCallback<S extends Store, P extends { [key: string]: any }> = (
	store: S
) => GetStaticProps<P>;
export type GetServerSidePropsCallback<S extends Store, P extends { [key: string]: any }> = (
	store: S
) => GetServerSideProps<P>;
export type PageCallback<S extends Store, P> = (store: S) => GetInitialPageProps<P>;
export type AppCallback<S extends Store, P> = (store: S) => GetInitialAppProps<P>;
export type Callback<S extends Store, P extends { [key: string]: any }> =
	| GetStaticPropsCallback<S, P>
	| GetServerSidePropsCallback<S, P>
	| PageCallback<S, P>
	| AppCallback<S, P>;

import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import {
	IContactRecordParams,
	IContactRecordRes,
	IReportedRes,
	ReportPlatformService
} from '$lib/service/report-platform';

import { IReportPlatformState } from './types';

const fetchDefaultTab = createAsyncThunk(
	'reportPlatformModel/fetchDefaultTab',
	async (_, { dispatch }) => {
		const { data } = await dispatch(ReportPlatformService.fetchDefaultTab()).unwrap();
		return data;
	}
);

const fetchList = createAsyncThunk<
	{ data: IReportedRes['list']; params: { type: string; page: number; page_size: number } },
	{ type: string; page: number; page_size: number }
>('reportPlatformModel/fetchList', async (params, { dispatch }) => {
	const { data } = await dispatch(
		ReportPlatformService[params.type === '1' ? 'fetchReportedResume' : 'fetchReportedRecruit'](
			omit(params, 'type')
		)
	).unwrap();
	return { data: data.list, params };
});

const fetchContactRecord = createAsyncThunk<
	{ data: IContactRecordRes['list']; params: IContactRecordParams },
	IContactRecordParams
>('reportPlatformModel/fetchContactRecord', async (params, { dispatch, rejectWithValue }) => {
	try {
		const { data } = await dispatch(ReportPlatformService.fetchContactRecord(params)).unwrap();
		return { data: data.list, params };
	} catch (e) {
		return rejectWithValue(e);
	}
});

const reportPlatformModel = slice({
	name: 'reportPlatformModel',
	initialState: <IReportPlatformState>{
		activeKey: '0',
		recruitList: {
			data: [],
			requestParams: {
				page: 1,
				page_size: 15
			},
			hasMore: false
		},
		resumeList: {
			data: [],
			requestParams: {
				page: 1,
				page_size: 15
			},
			hasMore: false
		},
		contactRecord: {
			data: [],
			requestParams: {
				type: 'job',
				page: 1
			},
			hasMore: false
		}
	},
	reducers: {
		setState(state, { payload }) {
			return { ...state, ...payload } as IReportPlatformState;
		}
	},
	extraReducers: {
		[fetchDefaultTab.fulfilled.type]: (state, { payload }) => {
			if (payload.show_tab === 1) {
				state.activeKey = '0';
			} else state.activeKey = '1';
		},
		[fetchList.fulfilled.type]: (state, { payload }) => {
			const key = payload.params.type === '0' ? 'recruitList' : 'resumeList';
			if (state[key].data.length === 0 || payload.params.page === 1) {
				state[key].data = payload.data;
			} else state[key].data = state[key].data.concat(payload.data);
			state[key].requestParams = payload.params;
			state[key].hasMore = payload.data.length >= payload.params.page_size;
		},
		[fetchContactRecord.fulfilled.type]: (state, { payload }) => {
			/** 过滤掉info_id为0的数据 */
			const list = payload.data.filter((each: IContactRecordRes['list'][0]) => each.info_id);
			if (state.contactRecord.data.length === 0 || payload.params.page === 1) {
				/** 过滤掉 */
				state.contactRecord.data = list;
			} else state.contactRecord.data = state.contactRecord.data.concat(list);
			state.contactRecord.requestParams = payload.params;
			state.contactRecord.hasMore = payload.data.length > 0;
		}
	},
	effect: { fetchList, fetchContactRecord, fetchDefaultTab }
});

export default reportPlatformModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IShippingAddressInfo, YpStoreService } from '$lib/service/yp-store';
/** 查询收货地址 */
export const fetchShippingAddress = createAsyncThunk(
	'ypStoreAddressModel/fetchShippingAddress',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchShippingAddress()).unwrap();
		return data;
	}
);

/** 操作收货地址-更新或添加 */
export const submit = createAsyncThunk(
	'ypStoreAddressModel/submit',
	async (params: IShippingAddressInfo, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(
				YpStoreService[params.receiveAddrId ? 'updateShippingAddress' : 'postShippingAddress'](
					params
				)
			).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);
const ypStoreAddressModel = slice({
	name: 'ypStoreAddressModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchShippingAddress, submit }
});

export default ypStoreAddressModel;

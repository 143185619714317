import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import {
	IFetchSecondHandListParams,
	ISecondHandListData,
	SecondHandService
} from '$lib/service/second-hand';

import { ISendHandListState } from './types';

const fetchList = createAsyncThunk(
	'secondHandListModel/fetchList',
	async (params: Partial<IFetchSecondHandListParams> | undefined, { getState, dispatch }) => {
		const {
			secondHandListModel: { requestParams }
		} = <{ secondHandListModel: ISendHandListState }>getState();

		dispatch(secondHandListModel.actions.setParams({ ...omit(params, ['pageNum']) }));
		const tParams = params ? { ...requestParams, ...params } : requestParams;
		const { data } = await dispatch(SecondHandService.fetchList(tParams)).unwrap();
		return { params: tParams, data };
	}
);
const secondHandListModel = slice({
	name: 'secondHandListModel',
	initialState: {
		list: [],
		hasMore: false,
		requestParams: {
			areaId: '',
			provinceId: '',
			cityId: '',
			pageNum: 1,
			pageSize: 20,
			categoryId: [],
			categoryName: '',
			transactionType: ''
		}
	} as ISendHandListState,
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload } as ISendHandListState;
		},
		setParams: (state, { payload }) => {
			state.requestParams = { ...state.requestParams, ...payload };
		}
	},
	extraReducers: {
		[fetchList.fulfilled.type]: (
			state,
			{ payload }: { payload: { data: ISecondHandListData; params: IFetchSecondHandListParams } }
		) => {
			const { data, params } = payload;
			if (params.pageNum === 1) {
				state.list = data?.data ?? [];
			} else {
				state.list = data?.data ? state.list.concat(data.data) : state.list;
			}
			state.requestParams = { ...state.requestParams, ...params };
			state.hasMore = !!data?.data?.length;
		}
	},
	effect: {
		fetchList
	}
});

export default secondHandListModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IFetchWechatGetShareTeamInfoParams,
	WatermarkCameraService
} from '$lib/service/watermark-camera';

import { IInviteJoinTeamModelState } from './types';

export const fetchWechatGetShareTeamInfo = createAsyncThunk(
	'inviteJoinTeamModel.fetchWechatGetShareTeamInfo',
	async (params: IFetchWechatGetShareTeamInfoParams, { dispatch }) => {
		const { data } = await dispatch(
			WatermarkCameraService.fetchWechatGetShareTeamInfo(params)
		).unwrap();
		return data;
	}
);

const inviteJoinTeamModel = slice({
	name: 'inviteJoinTeamModel',
	initialState: {
		wechatGetShareTeamInfo: {}
	} as IInviteJoinTeamModelState,
	reducers: {},
	extraReducers: {
		[fetchWechatGetShareTeamInfo.fulfilled.type]: (state, { payload }) => {
			return { ...state, wechatGetShareTeamInfo: payload };
		}
	},
	effect: {
		fetchWechatGetShareTeamInfo
	}
});

export default inviteJoinTeamModel;

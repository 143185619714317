import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { VideoIntegralServise } from '$lib/service/video-integral';

const fetchRuleConfig = createAsyncThunk(
	'fetchRuleModel/fetchRuleConfig',
	async (_, { dispatch }) => {
		try {
			const { data } = await dispatch(VideoIntegralServise.fetchRule()).unwrap();
			return data;
		} catch {}
	}
);

const fetchRuleModel = slice({
	name: 'fetchRuleModel',
	initialState: {},
	reducers: {},
	effect: {
		fetchRuleConfig
	}
});

export default fetchRuleModel;

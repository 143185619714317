import { createAsyncThunk } from '@reduxjs/toolkit';
import { Router } from 'next/router';

import { toast } from '$components/shared/toast';
import { slice } from '$lib/model/utils';
import wrapper, { IReduxContext } from '$lib/model/wrapper/ypStore';
import {
	IActivityResult,
	ICreateOrderParams,
	ICreateOrderRes,
	IFetchProductDetailParams,
	IProductDetailRes,
	IPurchaseResultRes,
	YpStoreService
} from '$lib/service/yp-store';
import { getReduxCtxSync } from '$lib/utils/getReduxContext';

import { IYpStoreDetailState } from './types';

/** 获取商品详情 */
const fetchDetail = createAsyncThunk<IProductDetailRes, IFetchProductDetailParams>(
	'ypStoreDetailModel/fetchDetail',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.fetchProductDetail(params)).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

/** 查询收货地址 */
export const fetchShippingAddress = createAsyncThunk(
	'ypStoreDetailModel/fetchShippingAddress',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchShippingAddress()).unwrap();
		return data;
	}
);

/** 获取用户积分信息 */
export const fetchUserIntegral = createAsyncThunk(
	'ypStoreDetailModel/fetchUserIntegral',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchUserIntegral()).unwrap();
		return data;
	}
);

/**
 * 提交订单
 */
const submit = createAsyncThunk<ICreateOrderRes, ICreateOrderParams>(
	'ypStoreDetailModel/submit',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.createOrder(params)).unwrap();
			return data;
		} catch (e: any) {
			if (e?.message) toast.show(e.message);
			return rejectWithValue(e);
		}
	}
);

/**
 * 获取支付结果
 */
const fetchPurchaseResult = createAsyncThunk<IPurchaseResultRes, string>(
	'ypStoreDetailModel/fetchPurchaseResult',
	async (tradeNo, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchPurchaseResult({ tradeNo })).unwrap();
		return data;
	}
);

/** 获取活动中奖结果 */
const fetchActivityResult = createAsyncThunk<IActivityResult, string>(
	'ypStoreDetailModel/fetchActivityResult',
	async (orderNo, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.fetchActivityResult({ orderNo })).unwrap();
			if (String(data.activityFlag) === 'true') return data;
			return rejectWithValue(data);
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

export const INITIAL_STATE = {
	info: {},
	purchaseInfo: {
		spec: '',
		count: 1,
		pay_channel: 'WEIXIN',
		pay_type: 'ONLINE_PAY'
	},
	integralInfo: {},
	shippingAddress: {}
};

const ypStoreDetailModel = slice({
	name: 'ypStoreDetailModel',
	initialState: <IYpStoreDetailState>INITIAL_STATE,
	reducers: {
		initState() {
			return INITIAL_STATE as IYpStoreDetailState;
		},
		setState(state, { payload }) {
			return { ...state, ...payload } as IYpStoreDetailState;
		}
	},
	extraReducers: {
		[fetchDetail.fulfilled.type]: (state, { payload }) => {
			if (!state.purchaseInfo.spec) state.purchaseInfo.spec = payload?.specifications[0];
			state.info = payload;
		},
		[fetchShippingAddress.fulfilled.type]: (state, { payload }) => {
			state.shippingAddress = payload;
		},
		[fetchUserIntegral.fulfilled.type]: (state, { payload }) => {
			state.integralInfo = payload;
		}
	},
	effect: {
		fetchDetail,
		fetchShippingAddress,
		fetchUserIntegral,
		submit,
		fetchPurchaseResult,
		fetchActivityResult
	}
});
const PERSIST_URLS = [
	/\/store\/YPMALL_.*/,
	/\/store\/confirm-order\//,
	/\/store\/shipping-address/
];
Router.events.on('routeChangeComplete', async (e) => {
	if (!PERSIST_URLS.some((reg) => !!reg.test(e))) {
		getReduxCtxSync<IReduxContext>(wrapper).dispatch(ypStoreDetailModel.actions.initState());
	}
});
export default ypStoreDetailModel;

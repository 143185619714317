import { createAsyncThunk } from '@reduxjs/toolkit';
import router from 'next/router';

import { dialog } from '$components/shared/dialog';
import { toast } from '$components/shared/toast';
import { slice } from '$lib/model/utils';
import {
	IResponseRadio2,
	IRportCentreWorkerOrYpForm,
	ReportCentreService
} from '$lib/service/report-centre';

const getConfig = createAsyncThunk(
	'reportCentreWorkerModel/getConfig',
	async (id: string | number, { dispatch }) => {
		try {
			const { data } = await dispatch(
				ReportCentreService.reportWorkerCategory({ appId: 102 })
			).unwrap();
			return { data, id };
		} catch (e) {
			return {};
		}
	}
);

const getYpConfig = createAsyncThunk(
	'reportCentreWorkerModel/getYpConfig',
	async (id: string | number, { dispatch }) => {
		try {
			const { data } = await dispatch(
				ReportCentreService.reportYpCategory({ appId: 102 })
			).unwrap();
			return { data, id };
		} catch (e) {
			return {};
		}
	}
);

/** 点击联系客服 */
const contactCustomer = (dispatch: any) => {
	dispatch(reportCentreWorkerModel.actions.openCall());
	router.back();
};

const submitWorkerReport = createAsyncThunk(
	'reportCentreWorkerModel/submitWorkerReport',
	async (
		{ files, classificationId, content }: Omit<IRportCentreWorkerOrYpForm, 'appId' | 'configId'>,
		{ dispatch }
	) => {
		try {
			const { data } = await dispatch(
				ReportCentreService.submitWorkerForm({ appId: 102, files, classificationId, content })
			).unwrap();
			dialog.show({
				title: '温馨提示',
				content:
					'感谢您的反馈，我们将在工作时间 24 小时内为您处理，请耐心等待，如有疑问请联系人工客服，客服电话：400-838-1888',
				confirmButtonText: '返回',
				cancelButtonText: '联系客服',
				onCancel: contactCustomer.bind(this, dispatch),
				onConfirm: router.back
			});
			return { data };
		} catch (e: any) {
			e.message && toast.show({ content: e.message, duration: 3000 });
			return {};
		}
	}
);

const submitYpReport = createAsyncThunk(
	'reportCentreWorkerModel/submitYpReport',
	async (
		{ files, classificationId, content }: Omit<IRportCentreWorkerOrYpForm, 'appId' | 'configId'>,
		{ dispatch }
	) => {
		try {
			const { data } = await dispatch(
				ReportCentreService.submitYpForm({ appId: 102, files, classificationId, content })
			).unwrap();
			dialog.show({
				title: '温馨提示',
				content:
					'感谢您的反馈，我们将在工作时间 24 小时内为您处理，请耐心等待，如有疑问请联系人工客服，客服电话：400-838-1888',
				confirmButtonText: '返回',
				cancelButtonText: '联系客服',
				onCancel: contactCustomer.bind(this, dispatch),
				onConfirm: router.back
			});
			return { data };
		} catch (e: any) {
			e.message && toast.show({ content: e.message, duration: 3000 });
			return {};
		}
	}
);

const reportCentreWorkerModel = slice({
	name: 'reportCentreWorkerModel',
	initialState: { data: <{ category: IResponseRadio2[] }>{ category: [] }, callService: false },
	reducers: {
		openCall(state) {
			state.callService = true;
		},
		closeCall(state) {
			state.callService = false;
		}
	},
	extraReducers: {
		[getConfig.fulfilled.type]: (state, { payload }) => {
			state.data = payload.data;
		},
		[getYpConfig.fulfilled.type]: (state, { payload }) => {
			state.data = payload.data;
		}
	},
	effect: { getConfig, submitWorkerReport, submitYpReport, getYpConfig }
});

export default reportCentreWorkerModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { ConfigService, IFetchWxConfigRes } from '$lib/service/config';

import { slice } from '../utils';
import { IConfigState } from './types';

export const fetchAreaData = createAsyncThunk(
	'configModel/fetchAreaData',
	async (_, { dispatch, getState }) => {
		const { configModel: configState } = <{ configModel: IConfigState }>getState();
		if (configState.areaData.length) {
			return configState.areaData;
		} else {
			const { data } = await dispatch(ConfigService.fetchAreaData()).unwrap();
			return data;
		}
	}
);

export const fetchSecondHandCategory = createAsyncThunk(
	'configModel/fetchSecondHandCategory',
	async (params: { refresh?: boolean } | undefined, { dispatch, getState }) => {
		const { configModel: configState } = <{ configModel: IConfigState }>getState();
		if (configState.secondHandCategory.length && !params?.refresh) {
			return configState.secondHandCategory;
		} else {
			const { data } = await dispatch(ConfigService.fetchSecondHandCategory()).unwrap();
			return data;
		}
	}
);

export const fetchWxConfig = createAsyncThunk<IFetchWxConfigRes, { url: string }>(
	'configModel/fetchWxConfig',
	async (params, { dispatch }) => {
		const { data } = await dispatch(ConfigService.fetchWxConfig(params)).unwrap();
		return data;
	}
);
const configModel = slice({
	name: 'configModel',
	initialState: <IConfigState>{
		areaData: [],
		secondHandCategory: [],
		headers: {},
		platform: 'h5',
		query: { source: 1, mode: 1, top: 0, bottom: 0 },
		params: {}
	},
	reducers: {
		setState(state, { payload }) {
			return { ...state, ...payload } as IConfigState;
		}
	},
	extraReducers: {
		[fetchAreaData.fulfilled.type]: (state, { payload }) => {
			state.areaData = payload;
		},
		[fetchSecondHandCategory.fulfilled.type]: (state, { payload }) => {
			state.secondHandCategory = payload;
		}
	},
	effect: {
		fetchAreaData,
		fetchWxConfig,
		fetchSecondHandCategory
	}
});

export const subtrees = [
	'configModel.headers',
	'configModel.query',
	{ subtree: 'configModel.platform', compress: false }
];

export default configModel;

/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2024-01-09 15:31:49
 * @Description: 
 */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IMemberVipResourcePageParams,
	MemberVipService,
	ResourceList,
	ResourceType
} from '$lib/service/member-vip';
import { UserService } from '$lib/service/user';

import { IMemberVipEquityState } from './types';

/** 更多会员推荐 */
const fetchPageList = createAsyncThunk(
	'memberVipRecordNewModel/vipPageList',
	async ({ userrole, ...rest }: IMemberVipResourcePageParams, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.vipMemberMoreList(rest, userrole!)).unwrap();
		return data;
	}
);

/** 用户信息 */
const fetchUserCenter = createAsyncThunk(
	'memberVipEquityNewModel/userCenter',
	async (_, { dispatch }) => {
		const { data } = await dispatch(UserService.fetchUserCenter()).unwrap();
		return data;
	}
)

/** 会员中心横幅 */
const fetchVipResumeMember = createAsyncThunk(
	'memberVipEquityNewModel/vipResumeMember',
	async (_, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.vipResumeMember()).unwrap();
		return data;
	}
)

/** 简历是否存在 */
const fetchResumeExist = createAsyncThunk(
	'memberVipEquityNewModel/resumeExist',
	async (_, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.resumeExist()).unwrap()
		return data
	}
)
const memberVipEquityNewModel = slice({
	name: 'memberVipEquityNewModel',
	initialState: {
		vipPageList: [],
		userInfo: {},
		resultList: [],
		resultExist: {}
	} as unknown as IMemberVipEquityState,
	reducers: {},
	extraReducers: {
		[fetchPageList.fulfilled.type]: (state, { payload }) => {
			state.vipPageList = payload.resourceList?.reduce(
				(prev: ResourceList[], curr: ResourceType) => {
					prev.push(...curr.list);
					return prev;
				},
				[]
			);
		},
		[fetchUserCenter.fulfilled.type]: (state, { payload }) => {
			state.userInfo = payload
		},
		[fetchVipResumeMember.fulfilled.type]: (state, { payload }) => {
			state.resultList = payload.resultList
		},
		[fetchResumeExist.fulfilled.type]: (state, { payload }) => {
			state.resultExist = payload
		},
	},
	effect: {
		fetchPageList,
		fetchUserCenter,
		fetchVipResumeMember,
		fetchResumeExist
	}
});

export default memberVipEquityNewModel;

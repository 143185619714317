import { createSlice, CreateSliceOptions, Slice, SliceCaseReducers } from '@reduxjs/toolkit';

import { HYDRATE } from './redux-wrapper';

const slice = <
	State,
	CaseReducers extends SliceCaseReducers<State>,
	Name extends string = string,
	E extends any = any
>(
	options: CreateSliceOptions<State, CaseReducers, Name> & { effect?: E }
): Slice<State, CaseReducers, Name> & {
	actions: Slice<State, CaseReducers, Name>['actions'] & E;
} => {
	const sliceInstance = createSlice({
		...options,
		extraReducers: {
			...options.extraReducers,
			[HYDRATE]: (state, { payload }) => ({
				...state,
				...payload[options.name]
			})
		}
	});
	return {
		...sliceInstance,
		actions: { ...sliceInstance.actions, ...(options?.effect ? (options?.effect as any) : {}) }
	};
};

export { slice };

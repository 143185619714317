import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	AwardDetailService,
	IFetchAwardsReq,
	IFetchCashOutAccountReq
} from '$lib/service/award-detail';

const fetchCashOutAccount = createAsyncThunk(
	'awardDetailModel/fetchCashOutAccount',
	async (params: IFetchCashOutAccountReq, { dispatch }) => {
		const {
			data: { earnings }
		} = await dispatch(AwardDetailService.fetchCashOutAccount(params)).unwrap();
		return earnings;
	}
);

const fetchAwards = createAsyncThunk(
	'awardDetailModel/fetchAwards',
	async (params: IFetchAwardsReq, { dispatch }) => {
		const {
			data: { list, pagination }
		} = await dispatch(AwardDetailService.fetchAwards(params)).unwrap();
		return { list, pagination };
	}
);

const awardDetailModel = slice({
	name: 'awardDetailModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchCashOutAccount, fetchAwards }
});

export default awardDetailModel;

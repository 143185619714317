import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IGetAlbumMemberResourceParams } from '$lib/service/watermark-camera/types';
import WatermarkCameraService from '$lib/service/watermark-camera/WatermarkCameraService';
import { arrSplitByTime } from '$lib/utils/tools';

import { WatermarkImgShareModelInitialState } from './types';

export const getAlbumMemberResource = createAsyncThunk(
	'watermarkImgShareModel.getAlbumMemberResource',
	async (params: IGetAlbumMemberResourceParams, { dispatch }) => {
		const { data } = await dispatch(WatermarkCameraService.getAlbumMemberResource(params)).unwrap();
		return { data, params };
	}
);

const watermarkImgShareModel = slice({
	name: 'watermarkImgShareModel',
	initialState: {
		data: [],
		list: [],
		request: {
			currentPage: 1,
			pageSize: 10,
			code: ''
		},
		hasMore: false,
		sharerInfo: {}
	} as WatermarkImgShareModelInitialState,
	reducers: {
		setRequest: (state, { payload }) => {
			state.request = payload;
		}
	},
	extraReducers: {
		[getAlbumMemberResource.fulfilled.type]: (state, { payload }) => {
			if (!payload) return;
			const { data } = payload;
			const list = state.data || [];
			const newList = list.concat(data.photos.data);
			state.data = newList;
			state.list = arrSplitByTime(newList);
			state.hasMore = data.photos.data.length == 0 ? false : true;
			state.request = { ...payload.params, currentPage: state.request.currentPage + 1 };
			state.sharerInfo = { avatar: data.avatar, name: data.name, expireDate: data.expireDate };
		}
	},
	effect: {
		getAlbumMemberResource
	}
});

export default watermarkImgShareModel;

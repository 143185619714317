import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import { AppState } from '$lib/model/wrapper/ypStore';
import { IFetchGroupBookingListParams, YpStoreService } from '$lib/service/yp-store';

import { IGroupBookingManageState } from './types.d';

const fetchList = createAsyncThunk(
	'ypStoreGroupBookingManageModel/fetchList',
	async (params: IFetchGroupBookingListParams & { type: string }, { dispatch }) => {
		const { data } = await dispatch(
			YpStoreService[params.type === '0' ? 'fetchGroupBookingList' : 'fetchSelfGroupBookingList'](
				omit(params, 'type')
			) as any
		).unwrap();
		// const data = [{}, {}, {}];
		return { data: data.data, params };
	}
);

/** 获取最新的列表数据 */
const revalidateList = createAsyncThunk(
	'ypStoreGroupBookingManageModel/revalidateList',
	async (params: { type: string }, { dispatch, getState }) => {
		const state = (getState() as AppState).ypStoreGroupBookingManageModel;
		const requestParams = state[params.type === '0' ? 'productList' : 'selfList']
			.requestParams as IFetchGroupBookingListParams;
		const { data } = await dispatch(
			YpStoreService[params.type === '0' ? 'fetchGroupBookingList' : 'fetchSelfGroupBookingList']({
				...requestParams,
				currentPage: 1,
				pageSize: requestParams.currentPage * requestParams.pageSize
			}) as any
		).unwrap();
		return { data: data.data, params };
	}
);

/**
 * 鱼泡商城-拼团管理-model
 */
const ypStoreGroupBookingManageModel = slice({
	name: 'ypStoreGroupBookingManageModel',
	initialState: <IGroupBookingManageState>{
		productList: {
			requestParams: {
				currentPage: 1,
				pageSize: 15
			},
			hasMore: false,
			data: []
		},
		selfList: {
			requestParams: {
				currentPage: 1,
				pageSize: 15
			},
			hasMore: false,
			data: []
		}
	},
	reducers: {},
	extraReducers: {
		[fetchList.fulfilled.type]: (state, { payload }) => {
			const key = payload.params.type === '0' ? 'productList' : 'selfList';
			if (state[key].data.length === 0 || payload.params.currentPage === 1) {
				state[key].data = payload.data;
			} else state[key].data = (state[key].data as any).concat(payload.data);
			state[key].requestParams = payload.params;
			state[key].hasMore = payload.data.length >= payload.params.pageSize;
		},
		[revalidateList.fulfilled.type]: (state, { payload }) => {
			const key = payload.params.type === '0' ? 'productList' : 'selfList';
			state[key].data = payload.data;
		}
	},
	effect: { fetchList, revalidateList }
});

export default ypStoreGroupBookingManageModel;

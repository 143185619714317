import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	BatchStatus,
	BatchStatusResult,
	ICouponRightQueryReq,
	IGetOccInfoParams,
	IIosBuyLimitParams,
	IMemberVipOrderParams,
	IMemberVipOrderRes,
	IMemberVipParams,
	IMemberVipPurchaseData,
	IMemberVipRenewData,
	IMemberVipRenewParams,
	IMemberVipUpgradeData,
	IMemberVipUpgradeParams,
	IMvipInfo,
	IOccInfosData,
	IOrderNoStatusRes,
	IosRmbBuyLimitRiskCheck,
	IPreOrderNo,
	IPurchaseResultRes,
	IQueryCouponData,
	IQueryCouponParams,
	IQueryHitGrayParams,
	IQueryHitGrayQueryData,
	IRecordParams,
	IVoucherRetainData,
	IVoucherRetainParams,
	MemberVipService,
	UserCenter
} from '$lib/service/b-member-vip';
import { IUserIntegralInfo, YpStoreService } from '$lib/service/yp-store';

import { formatVipData } from '../order/utils';
import { IMemberVipDetailState } from './types';

/** 获取用户会员数据 */
const getIosRmbBuyLimitFlag = createAsyncThunk<any, IIosBuyLimitParams>(
	'bMemberVipDetailModel/getIosRmbBuyLimitFlag',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.getIosRmbBuyLimitFlag(params)).unwrap();
		return data;
	}
);

/**招聘会员查询 */
const getVipGeneralQuery = createAsyncThunk<Omit<IMvipInfo, 'expiringRightDetailList'>>(
	'bMemberVipDetailModel/getVipGeneralQuery',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.getGeneralQuery()).unwrap();
		return data;
	}
);

/**次卡套餐查询 */
const getCiKaTimesQuery = createAsyncThunk<Omit<IMvipInfo, 'otherRightDetailList'>>(
	'bMemberVipDetailModel/getCiKaTimesQuery',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.getCiKaTimesQuery()).unwrap();
		return data;
	}
);

/** 获取用户会员数据 */
const vipMembership = createAsyncThunk<any, any>(
	'bMemberVipDetailModel/vipMembership',
	async ({ vipType, vipGroup, isNewPurchase, callBack }, { dispatch }) => {
		const service = isNewPurchase
			? MemberVipService.vipMembershipV3
			: MemberVipService.vipMembership;
		const { data } = await dispatch(service({ vipType, vipGroup })).unwrap();
		callBack && callBack(data);
		return data;
	}
);
/** 会员和次卡商品详情 */
const vipPurchaseQuery = createAsyncThunk<IMemberVipPurchaseData, IMemberVipParams>(
	'bMemberVipDetailModel/purchaseQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { callback, vipGroup, goodsGroupCode, businessType } = params;
			/** 商品组编码：会员卡-GENERAL_VIP，次卡-TIMES_VIP	 */
			let data;
			const { data: PreData } = await dispatch(
				MemberVipService.newPurchaseQuery({ goodsGroupCode, vipGroup, businessType })
			).unwrap();
			data = formatVipData(PreData, goodsGroupCode!);
			callback && callback(data);
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员续费 */
const vipRenewQuery = createAsyncThunk<IMemberVipRenewData, IMemberVipRenewParams>(
	'bMemberVipDetailModel/renewQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.renewQuery(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员升级 */
const vipUpgradeQuery = createAsyncThunk<IMemberVipUpgradeData, IMemberVipUpgradeParams>(
	'bMemberVipDetailModel/fetchVipDetailQuery',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.upgradeQuery(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 提交订单 */
const submit = createAsyncThunk<IMemberVipOrderRes, IMemberVipOrderParams>(
	'bMemberVipDetailModel/submit',
	async ({ ...params }, { dispatch, rejectWithValue }) => {
		try {
			const service = MemberVipService.createOrderV2;
			const { data } = await dispatch(service(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/**
 * 获取支付结果
 */
const fetchPurchaseResult = createAsyncThunk<IPurchaseResultRes, string>(
	'ypStoreDetailModel/fetchPurchaseResult',
	async (tradeNo, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchPurchaseResult({ tradeNo })).unwrap();
		return data;
	}
);
/** 查询支付是否走工行 */
const fetchHitGray = createAsyncThunk<IQueryHitGrayQueryData, IQueryHitGrayParams>(
	'bMemberVipDetailModel/fetchHitGray',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.queryHitGray(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 获取用户积分 */
const fetchUserIntegral = createAsyncThunk<IUserIntegralInfo>(
	'bMemberVipDetailModel/getIntegral',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.fetchUserIntegral()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
const marketGoodsPackageGroup = createAsyncThunk<any>(
	'bMemberVipDetailModel/marketGoodsPackageGroup', async (params: any, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.marketGoodsPackageGroup(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
)
/** 根据id查询工种详情 */
const getOccInfos = createAsyncThunk<IOccInfosData, IGetOccInfoParams>(
	'bMemberVipDetailModel/getOccInfos',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.getOccInfos(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 获取预下单号（开启工行支付时才调用） */
const fetchPreOrderNo = createAsyncThunk<IPreOrderNo>(
	'bMemberVipDetailModel/fetchPreOrderNo',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.fetchPreOrderNo()).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 查询订单结果 */
const fetchByOrderNo = createAsyncThunk<IOrderNoStatusRes, { orderNo: string }>(
	'bMemberVipDetailModel/fetchByOrderNo',
	async (params, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.fetchByOrderNo(params)).unwrap();
		return data;
	}
);
/** 卡券列表 */
const queryCoupon = createAsyncThunk<IQueryCouponData, IQueryCouponParams>(
	'bMemberVipDetailModel/queryCoupon',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(MemberVipService.queryCoupon(params)).unwrap();
			return data;
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);
/** 会员弹窗优惠券挽留 */
const voucherRetain = createAsyncThunk<IVoucherRetainData, IVoucherRetainParams>(
	'bMemberVipDetailModel/voucherRetain',
	async (params, { dispatch }) => {
		try {
			const { data } = await dispatch(MemberVipService.voucherRetain(params)).unwrap();
			return data;
		} catch (e: any) {
			return e;
		}
	}
);

/**获取用户信息 */
const getUserCenter = createAsyncThunk<UserCenter>(
	'bMemberVipDetailModel/getUserCenter',
	async (params, { dispatch }) => {
		try {
			const { data } = await dispatch(MemberVipService.getUserCenter()).unwrap();
			return data;
		} catch (e: any) {
			return e;
		}
	}
);

/** 限制检查风控 */
const getIosRmbBuyLimitRiskCheck = createAsyncThunk<
	IosRmbBuyLimitRiskCheck,
	{ riskBusinessType: 'YUPAO_POINTS_CHARGE' | 'YUPAO_B_VIP' }
>('bMemberVipDetailModel/voucherRetain', async (params, { dispatch }) => {
	try {
		const { data } = await dispatch(MemberVipService.getIosRmbBuyLimitRiskCheck(params)).unwrap();
		return data;
	} catch (e: any) {
		return e;
	}
});
/** 批量查询商品组状态 */
const getBatchStatus = createAsyncThunk<
	BatchStatus,
	{
		goodsGroupCodeList: string[];
		callBack?: (data?: BatchStatusResult[]) => Promise<void> | null;
	}
>('bMemberVipDetailModel/getBatchStatus', async (params, { dispatch }) => {
	try {
		const { goodsGroupCodeList, callBack } = params;
		const { data } = await dispatch(
			MemberVipService.getBatchStatus({ goodsGroupCodeList })
		).unwrap();
		callBack && callBack(data.resultList);
		return data;
	} catch (e: any) {
		return e;
	}
});

/** 会员购买记录 */
const fetchRecordList = createAsyncThunk(
	'memberVipRecordModel/recordList',
	async (params: IRecordParams, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.recordList(params)).unwrap();
		return data;
	}
);

/** 推荐会员列表 */
const fetchRecommendList = createAsyncThunk(
	'memberVipRecordModel/recommendList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.recommendList()).unwrap();
		return data;
	}
);

/** 优惠券 列表 */
const couponRightQuery = createAsyncThunk(
	'memberVipRecordModel/couponRightQuery',
	async (params: ICouponRightQueryReq, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.couponRightQuery(params)).unwrap();
		return data;
	}
);

const INIT_STATE = {
	/** 会员商品信息 */
	memberInfo: {
		uid: 0,
		name: '',
		vipRightList: [],
		supportVipTypeList: []
	},
	/** 会员列表 */
	vipInfoList: {},
	/** 当前选择的会员套餐详情 */
	selectInfoVipDetail: {},
	vipInfo: {
		status: 0 | 1 | 2 /**会员状态 -1-无会员,-2-会员已过期,1-会员有效*/,
		endTime: '',
		rightDetailList: [] /**次卡生效权益详情集 */,
		otherRightDetailList: []
	} /** 招聘会员信息 */,
	ciKaInfo: {
		status: 0 | 1 | 2 /**会员状态 -1-无会员,-2-会员已过期,1-会员有效*/,
		endTime: '',
		rightDetailList: [] /**次卡生效权益详情集 */,
		expiringRightDetailList: [] /**即将过期权益详情集 */
	} /** 次卡套餐信息 */,
	/** 会员详情(统一格式化成一个结构处理数据) */
	vipDetail: {
		delayWarn: false,
		vipSpuList: []
	},
	// 次卡商品信息
	cikaInfo: {},
	// 次卡详情
	cikaInfoDetail: {},
	/** 订单信息 */
	orderInfo: {
		payChannel: 'WEIXIN',
		payType: 'ONLINE_PAY',
		vipType: 1006,
		vipSpu: '',
		type: 0,
		showIntegralChannel: false
	},
	secondary: [],
	/** 积分信息 */
	userIntegral: {},
	/** 卡券列表 */
	couponList: [],
	/** 交易开关 */
	trade: {
		limitFlag: false,
		popupFlag: ''
	},
	/**用户信息 */
	userInfo: {},
	/**在会员页面购买次卡的支付弹款字段 */
	isCikaBuyPopup: false,
	/**在会员页面购买次卡选择的次卡 */
	selectCikaInfoDetail: {},
	/**在会员页面购买次卡选择的支付方式 */
	ciKapayChannel: '',

	/**购买记录的字段 */
	recordList: [],
	hasMore: false,
	recordParams: {
		currentPage: 1,
		pageSize: 100
	},
	recommendList: [],
	vipPageList: [],
	/**次卡详情页面的数据 */
	isChecked: 0,
	titleObj: {
		goodsGroupName: '',
		introduce: '',
		picture: '',
		goodsGroupCode: ''
	},
	selectInfoDetail: {},
	cikaOrderInfo: {
		payChannel: 'WEIXIN',
		payType: 'ONLINE_PAY',
		vipType: 1007,
		vipSpu: '',
		type: 0,
		showIntegralChannel: false
	},
	orderCarousel: [],
	lastPayChannel: 'WEIXIN',
	fullScreen: false,
	ticketList: [],
};
const bMemberVipDetailModel = slice({
	name: 'bMemberVipDetailModel',
	initialState: INIT_STATE as unknown as IMemberVipDetailState,
	reducers: {
		initState() {
			return INIT_STATE as unknown as IMemberVipDetailState;
		},
		setState(state, { payload }) {
			return { ...state, ...payload } as IMemberVipDetailState;
		}
	},
	extraReducers: {
		[fetchRecordList.fulfilled.type]: (state, { payload }) => {
			const { data, paginator } = payload;
			state.recordList = paginator?.currentPage === 1 ? data : [...state.recordList, ...data];
			state.recordParams.currentPage = paginator?.currentPage;
			state.hasMore = data?.length >= paginator?.pageSize;
		},
		[fetchRecommendList.fulfilled.type]: (state, { payload }) => {
			state.recommendList = payload;
		},
		[getBatchStatus.fulfilled.type]: (state, { payload }) => {
			state.secondary = payload.resultList;
		},
		[getUserCenter.fulfilled.type]: (state, { payload }) => {
			state.userInfo = payload;
		},
		[getVipGeneralQuery.fulfilled.type]: (state, { payload }) => {
			state.vipInfo = payload;
		},
		[getCiKaTimesQuery.fulfilled.type]: (state, { payload }) => {
			state.ciKaInfo = payload;
		},
		[vipMembership.fulfilled.type]: (state, { payload }) => {
			state.memberInfo = payload;
		},
		[getIosRmbBuyLimitFlag.fulfilled.type]: (state, { payload }) => {
			state.trade = payload;
		},
		[vipPurchaseQuery.fulfilled.type]: (state, { payload }) => {
			state.vipDetail = payload;
		},
		[vipRenewQuery.fulfilled.type]: (state, { payload }) => {
			// 格式化统一结构
			const { delayWarn, ...ext } = payload;
			state.vipDetail = {
				vipSpuList: [ext],
				delayWarn
			};
		},
		[vipUpgradeQuery.fulfilled.type]: (state, { payload }) => {
			const { delayWarn, price, integralPrice, upgradeToVipSku, upgradeFromVipSku, ...ext } =
				payload;
			const { vipSku, name } = payload.upgradeToVipSku;
			/** 过滤数据 */
			ext.vipSkuList = [
				{
					discountPrice: price,
					integralDiscountPrice: integralPrice,
					vipSku,
					name,
					integralPrice,
					price
				}
			];
			state.vipDetail = {
				vipSpuList: [ext],
				upgradeToVipSku,
				upgradeFromVipSku,
				delayWarn
			};
		},
		[fetchUserIntegral.fulfilled.type]: (state, { payload }) => {
			state.userIntegral = payload;
		},
		[queryCoupon.fulfilled.type]: (state, { payload }) => {
			state.couponList = payload?.voucherList || [];
		},
		[couponRightQuery.fulfilled.type]: (state, { payload }) => {
			state.ticketList = payload?.list || [];
		},
	},
	effect: {
		fetchRecordList,
		fetchRecommendList,
		getCiKaTimesQuery /** 次卡套餐查询 */,
		getVipGeneralQuery /**招聘会员查询 */,
		vipMembership,
		vipPurchaseQuery,
		submit,
		fetchPurchaseResult,
		vipRenewQuery,
		fetchHitGray,
		vipUpgradeQuery,
		fetchUserIntegral,
		getOccInfos,
		fetchPreOrderNo,
		fetchByOrderNo,
		queryCoupon,
		voucherRetain,
		getIosRmbBuyLimitFlag,
		getIosRmbBuyLimitRiskCheck,
		getBatchStatus,
		getUserCenter,
		couponRightQuery
	}
});

export default bMemberVipDetailModel;

import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { get, post } from '$lib/http';
import { HttpOptions, PublicResult } from '$lib/http/types';

export const postApi = function <R>(url: string, options?: Partial<HttpOptions>) {
	return createAsyncThunk(
		'post',
		(config: { url: string; options: Partial<HttpOptions> }, thunkAPI) => {
			const { getState, rejectWithValue } = thunkAPI;
			const { url, options } = config;
			const { configModel, userModel } = getState() as any;
			const dataCombine = options?.data ?? {};
			/** 小程序端统一增加wechat_token字段 */
			if (configModel?.platform === 'mini') {
				Object.assign(dataCombine, {
					wechat_token: options.php
						? configModel?.headers?.minipackage ?? ''
						: configModel?.headers?.packagename ?? ''
				});
			}

			return (async (): Promise<PublicResult<R>> => {
				try {
					const data = await post<R>(url, {
						...(options ?? {}),
						data: dataCombine,
						method: 'post',
						headers: {
							...omit(configModel.headers, ['appVersion', 'appversion', "Packageversion", "packageversion", "packageVersion"]),
							appversion:
								configModel.headers?.appversion ??
								configModel.headers?.appVersion ??
								configModel.headers?.packageVersion ??
								configModel.headers?.packageversion,
							packageversion:
								configModel.headers?.packageVersion ??
								configModel.headers?.packageversion ??
								configModel.headers?.appversion ??
								configModel.headers?.appVersion,
							token: userModel.token,
							...((options?.headers as any) ?? {})
						}
					});
					return data;
				} catch (e: any) {
					return rejectWithValue(e) as any;
				}
			})();
		}
	)({ url, options: options ?? {} });
};

export const getApi = function <R>(url: string, options?: Partial<HttpOptions>) {
	return createAsyncThunk(
		'get',
		(config: { url: string; options: Partial<HttpOptions> }, thunkAPI) => {
			const { getState, rejectWithValue } = thunkAPI;
			const { url, options } = config;
			const { configModel, userModel } = getState() as any;
			const paramsCombine = options?.params ?? {};
			if (configModel?.platform === 'mini') {
				Object.assign(paramsCombine, {
					wechat_token: options.php
						? configModel?.headers?.minipackage ?? ''
						: configModel?.headers?.packagename ?? ''
				});
			}
			return (async (): Promise<PublicResult<R>> => {
				try {
					const data = await get<R>(url, {
						...(options ?? {}),
						params: paramsCombine,
						method: 'get',
						headers: {
							...omit(configModel.headers, ['appVersion', 'appversion']),
							appversion:
								configModel.headers?.appversion ??
								configModel.headers?.appVersion ??
								configModel.headers?.packageVersion ??
								configModel.headers?.packageversion,
							token: userModel.token,
							...((options?.headers as any) ?? {})
						}
					});
					return data;
				} catch (e: any) {
					return rejectWithValue(e) as any;
				}
			})();
		}
	)({ url, options: options ?? {} });
};

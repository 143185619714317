export const DEFAULT_STATE = {
	activeKey: '0',
	pageLoaded: false,
	promoted: {
		requestParams: {
			currentPage: 1,
			pageSize: 15,
			type: 1000
		},
		list: [],
		hasMore: false
	},
	received: {
		requestParams: {
			currentPage: 1,
			pageSize: 15,
			type: 1000
		},
		list: [],
		hasMore: false
	}
};

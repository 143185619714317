/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2024-01-08 09:39:19
 * @Description: 
 */
import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IMemberVipResourcePageParams,
	IRecordParams,
	MemberVipService,
	ResourceList,
	ResourceType
} from '$lib/service/member-vip';

import { IMemberVipRecordState } from './types';

/** 会员购买记录 */
const fetchRecordList = createAsyncThunk(
	'memberVipRecordModel/recordList',
	async ({ isNewMorePurchase, ...params }: IRecordParams, { dispatch }) => {
		/** 610以上调用会员购买记录 */
		const method = isNewMorePurchase ? 'resumePageList' : 'recordList'
		const { data } = await dispatch(MemberVipService[method](params)).unwrap();
		return data;
	}
);

/** 更多会员推荐 */
const fetchPageList = createAsyncThunk(
	'memberVipRecordModel/vipPageList',
	async ({ userrole, ...rest }: IMemberVipResourcePageParams, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.vipMemberMoreList(rest, userrole!)).unwrap();
		return data;
	}
);

/** 推荐会员列表 */
const fetchRecommendList = createAsyncThunk(
	'memberVipRecordModel/recommendList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.recommendList()).unwrap();
		return data;
	}
);


const memberVipRecordModel = slice({
	name: 'memberVipRecordModel',
	initialState: {
		recordList: [],
		hasMore: false,
		recordParams: {
			currentPage: 1,
			pageSize: 100
		},
		recommendList: [],
		vipPageList: [],
	} as IMemberVipRecordState,
	reducers: {},
	extraReducers: {
		[fetchRecordList.fulfilled.type]: (state, { payload }) => {
			const { data, paginator } = payload;
			state.recordList = paginator?.currentPage === 1 ? data : [...state.recordList, ...data];
			state.recordParams.currentPage = paginator?.currentPage;
			state.hasMore = data?.length >= paginator?.pageSize;
		},
		[fetchRecommendList.fulfilled.type]: (state, { payload }) => {
			state.recommendList = payload;
		},
		[fetchPageList.fulfilled.type]: (state, { payload }) => {
			state.vipPageList = payload.resourceList?.reduce(
				(prev: ResourceList[], curr: ResourceType) => {
					prev.push(...curr.list);
					return prev;
				},
				[]
			);
		},
	},
	effect: {
		fetchRecordList,
		fetchRecommendList,
		fetchPageList
	}
});

export default memberVipRecordModel;

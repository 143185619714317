import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import { AppState } from '$lib/model/wrapper/deposit';
import {
	DepositService,
	IFetchPromotedInvitationsParams,
	IFetchReceivedInvitationsParams
} from '$lib/service/deposit';

import { DEFAULT_STATE } from './constants';
import { IInvitationRecordState } from './types';

const fetchPromoteList = createAsyncThunk(
	'invitationRecordModel/fetchPromoteList',
	async (
		params: IFetchPromotedInvitationsParams & {
			/** 是否让列表数据保持最新 */
			newest?: boolean;
		},
		{ dispatch, getState }
	) => {
		const {
			requestParams: { currentPage, pageSize }
		} = (getState() as AppState).invitationRecordModel.promoted;
		if (params.newest) {
			const page = currentPage || 1;
			params.pageSize = page * pageSize;
			params.currentPage = 1;
		}
		const { data } = await dispatch(
			DepositService.fetchPromotedInvitations(omit(params, 'newest'))
		).unwrap();
		return { params, data: data.data };
	}
);

const fetchReceiveList = createAsyncThunk(
	'invitationRecordModel/fetchReceiveList',
	async (
		params: IFetchReceivedInvitationsParams & {
			/** 是否让列表数据保持最新 */
			newest?: boolean;
		},
		{ dispatch, getState }
	) => {
		const {
			requestParams: { currentPage, pageSize }
		} = (getState() as AppState).invitationRecordModel.received;
		if (params.newest) {
			const page = currentPage || 1;
			params.pageSize = page * pageSize;
			params.currentPage = 1;
		}
		const { data } = await dispatch(
			DepositService.fetchReceivedInvitations(omit(params, 'newest'))
		).unwrap();
		return { params, data: data.data };
	}
);

const invitationRecordModel = slice({
	name: 'invitationRecordModel',
	initialState: <IInvitationRecordState>{
		...DEFAULT_STATE
	},
	reducers: {
		initState() {
			return DEFAULT_STATE;
		},
		setState(state, { payload }) {
			return { ...state, ...payload } as IInvitationRecordState;
		}
	},
	extraReducers: {
		[fetchPromoteList.fulfilled.type]: (state, { payload }) => {
			const { data, params } = payload;
			if (data) {
				if (params.currentPage === 1) {
					state.promoted.list = data;
				} else {
					state.promoted.list = state.promoted.list.concat(data);
				}
			}
			if (!params.newest) {
				state.promoted.hasMore = data?.length === params.pageSize;
				const _p = { ...state.promoted.requestParams, ...params };
				/** 当数据为0时,当前页码保持上次请求的页码 */
				if (!data?.length) _p.currentPage = state.promoted.requestParams.currentPage;
				state.promoted.requestParams = _p;
			}
		},
		[fetchReceiveList.fulfilled.type]: (state, { payload }) => {
			const { data, params } = payload;
			if (data) {
				if (params.currentPage === 1) {
					state.received.list = data;
				} else {
					state.received.list = state.received.list.concat(data);
				}
			}
			if (!params.newest) {
				state.received.hasMore = data?.length === params.pageSize;
				const _p = { ...state.received.requestParams, ...params };
				if (!data?.length) _p.currentPage = state.received.requestParams.currentPage;
				state.received.requestParams = _p;
			}
		}
	},
	effect: { fetchPromoteList, fetchReceiveList }
});

export default invitationRecordModel;

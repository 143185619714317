import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IPurchaseRecordRes, TeamMembersService } from '$lib/service/team-members';

const fetchList = createAsyncThunk<IPurchaseRecordRes, string | number>(
	'teamMembersModel/submit',
	async (userId, { dispatch }) => {
		const { data } = await dispatch(
			TeamMembersService.fetchPurchaseRecord({ userId, orderBizType: 'TEAMS_GROUP_VIP' })
		).unwrap();
		return data;
	}
);

/**
 * 班组VIP购买记录 model
 */
const teamMembersPurchaseRecordModel = slice({
	name: 'teamMembersPurchaseRecordModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchList }
});

export default teamMembersPurchaseRecordModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IFetchActiveDetailReq,
	IFetchActiveHistoryReq,
	InviteGiveCashService
} from '$lib/service/invite-give-cash';

import { IInviteGiveCashState } from './type';

const fetchActiveDetail = createAsyncThunk(
	'inviteGiveCashModel/fetchActiveDetail',
	async (params: IFetchActiveDetailReq, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchActiveDetail(params)).unwrap();
		return data;
	}
);

const fetchActiveDetailShow = createAsyncThunk(
	'inviteGiveCashModel/fetchActiveDetailShow',
	async (params: IFetchActiveDetailReq, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchActiveDetailShow(params)).unwrap();
		return data;
	}
);

const fetchActiveHistory = createAsyncThunk(
	'inviteGiveCashModel/fetchActiveHistory',
	async (params: IFetchActiveHistoryReq, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchActiveHistory(params)).unwrap();
		return data;
	}
);

const fetchRankings = createAsyncThunk(
	'inviteGiveCashModel/fetchRankings',
	async (params: IFetchActiveHistoryReq, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchRankings(params)).unwrap();
		return data;
	}
);

const fetchUrlScheme = createAsyncThunk(
	'inviteGiveCashModel/fetchUrlScheme',
	async (params: FormData, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchUrlScheme(params)).unwrap();
		return data;
	}
);

const fetchJoinActive = createAsyncThunk(
	'inviteGiveCashModel/fetchJoinActive',
	async (params: IFetchActiveHistoryReq, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(InviteGiveCashService.fetchJoinActive(params)).unwrap();
			return data;
		} catch (e: any) {
			if (e.head.code == 500) {
				return 'code500';
			}
			return rejectWithValue(e);
		}
	}
);
const fetchUserImg = createAsyncThunk(
	'fetchUserImg',
	async (params: { uid: string; encrypt?: string }, { dispatch }) => {
		const { data } = await dispatch(InviteGiveCashService.fetchUserImg(params)).unwrap();
		return data;
	}
);

const inviteGiveCashModel = slice({
	name: 'inviteGiveCashModel',
	initialState: <IInviteGiveCashState>{
		activeDetail: {},
		activeDetailShow: {}
	},
	reducers: {
		setActiveDetail(state, { payload }) {
			state.activeDetail = payload;
		}
	},
	extraReducers: {
		[fetchActiveDetail.fulfilled.type]: (state, { payload }) => {
			state.activeDetail = payload;
		},
		[fetchActiveDetailShow.fulfilled.type]: (state, { payload }) => {
			state.activeDetailShow = payload;
		}
	},
	effect: {
		fetchUserImg,
		fetchActiveDetail,
		fetchActiveHistory,
		fetchRankings,
		fetchUrlScheme,
		fetchJoinActive,
		fetchActiveDetailShow
	}
});

export default inviteGiveCashModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { VideoIntegralServise } from '$lib/service/video-integral';

const fetchRecord = createAsyncThunk('videoIntegralModel/fetchRecord', async (_, { dispatch }) => {
	const { data } = await dispatch(VideoIntegralServise.fetchRecord()).unwrap();
	return data;
});
const fetchInfo = createAsyncThunk('videoIntegralModel/fetchInfo', async (_, { dispatch }) => {
	const { data } = await dispatch(VideoIntegralServise.fetchInfo()).unwrap();
	return data;
});

const videoIntegralModel = slice({
	name: 'videoIntegralModel',
	initialState: {},
	reducers: {},
	effect: { fetchRecord, fetchInfo }
});
export default videoIntegralModel;

import { slice } from '$lib/model/utils';

const fraudPreventionGuideModel = slice({
	name: 'fraudPreventionGuideModel',
	initialState: {
		safeTopMargin: 0,
		videoPlay: false
	},
	reducers: {
		setSafeTopMargin(state, { payload }) {
			state.safeTopMargin = payload;
		},
		setVideoPlay(state, { payload }) {
			state.videoPlay = payload;
		}
	},
	extraReducers: {},
	effect: {}
});

export default fraudPreventionGuideModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ConfigService, IGetServerTimeResponse } from '$lib/service/config';
import {
	DepositService,
	IReceivedData,
	ISubmitClockParams,
	ISubmitClockRes
} from '$lib/service/deposit';

const getServerTime = createAsyncThunk<IGetServerTimeResponse>(
	'signInModel/getServerTime',
	async (_, { dispatch }) => {
		const { data } = await dispatch(ConfigService.getServerTime()).unwrap();
		return data;
	}
);

const fetchInvitationDetail = createAsyncThunk<IReceivedData, { id: number }>(
	'signInModel/fetchInvitationDetail',
	async () => {
		// const { data } = await dispatch(
		// 	DepositService.fetchInvitationDetail({ type: 1000, id })
		// ).unwrap();
		return {} as any;
	}
);

const submit = createAsyncThunk<ISubmitClockRes, ISubmitClockParams>(
	'signInModel/submit',
	async (params, { dispatch }) => {
		const { data } = await dispatch(DepositService.submitClock(params)).unwrap();

		return data;
	}
);

const signInModel = slice({
	name: 'signInModel',
	initialState: {},
	reducers: {},
	effect: { getServerTime, fetchInvitationDetail, submit }
});

export default signInModel;

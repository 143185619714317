import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { CommonService, IPaymentData, IPaymentStatusData } from '$lib/service/common';

const fetchPaymentInfo = createAsyncThunk<IPaymentData, { tradeNo: string }>(
	'authorizationModel/fetchPaymentInfo',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonService.fetchPaymentInfo(params)).unwrap();
		return data;
	}
);
const fetchPaymentStatus = createAsyncThunk<IPaymentStatusData, { tradeNo: string }>(
	'authorizationModel/fetchPaymentStatus',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonService.fetchPaymentStatus(params)).unwrap();
		return data;
	}
);
// const fetchWechatInfo = createAsyncThunk(
// 	'authorizationModel/fetchWechatInfo',
// 	async (params: IWxAuthorizationParams, { dispatch }) => {
// 		const { data } = await dispatch(CreditsService.fetchWechatInfo(params)).unwrap();
// 		dispatch(actions.userModel.setOpenId(data?.openId));
// 		return data;
// 	}
// );
const authorizationModel = slice({
	name: 'authorizationModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchPaymentInfo, fetchPaymentStatus }
});

export default authorizationModel;

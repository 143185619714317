import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ConfigService } from '$lib/service/config';
import { IActivityAppointResult, YpStoreService } from '$lib/service/yp-store';

const fetchResult = createAsyncThunk(
	'appointActivityModel/fetchResult',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchActivityAppointResult()).unwrap();
		return data;
	}
);

const getServerTime = createAsyncThunk(
	'appointActivityModel/getServerTime',
	async (_, { dispatch }) => {
		const { data } = await dispatch(ConfigService.getServerTime()).unwrap();
		return data.currentTime;
	}
);

const submitAppoint = createAsyncThunk(
	'appointActivityModel/submitAppoint',
	async (_, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(YpStoreService.activityAppoint()).unwrap();
			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const appointActivityModel = slice({
	name: 'appointActivityModel',
	initialState: <IActivityAppointResult>{
		success: false,
		endTime: '',
		serverTime: Date.now()
	},
	reducers: {
		setState(state, { payload }) {
			return { ...state, ...payload } as IActivityAppointResult;
		}
	},
	extraReducers: {
		[fetchResult.fulfilled.type]: (state, { payload }) => {
			state.endTime = payload.endTime;
			state.success = payload.success;
		},
		[getServerTime.fulfilled.type]: (state, { payload }) => {
			state.serverTime = payload;
		}
	},
	effect: { fetchResult, submitAppoint, getServerTime }
});

export default appointActivityModel;

import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { slice } from '$lib/model/utils';
import { RecruitmentDataCenterService } from '$lib/service/recruitment-data-center';

const recruitmentDataCenterList = createAsyncThunk(
	'recruitmentDataCenter/fetchRecruitmentDataCenterList',
	async (
		params:{
			bizType?: number,
			statDate?: number,
		},
		{ dispatch }
	) => {
		const { data } = await dispatch(
			RecruitmentDataCenterService.recruitmentDataCenterList(omit(params))
		).unwrap();
		return { data: data };
	}
);

const recruitmentDataCenterModel = slice({
	name: 'recruitmentDataCenterModel',
	initialState: {
		list:<any> {},
	},
	reducers: {
		setState: (state, { payload }) => {
			return { ...state, ...payload } as any;
		},
	},
	extraReducers: {
		[recruitmentDataCenterList.fulfilled.type]: (state, { payload }) => {
			const { data } = payload;
			state.list = data;
		},
	},
	effect: {recruitmentDataCenterList}
});

export default recruitmentDataCenterModel;


import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	CommonService,
	IPaymentData,
	IPaymentStatusData,
	queryTradeResultRes
} from '$lib/service/common';

const fetchPaymentInfo = createAsyncThunk<IPaymentData, { tradeNo: string }>(
	'paymentModel/fetchPaymentInfo',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonService.fetchPaymentInfo(params)).unwrap();
		return data;
	}
);
const fetchPaymentStatus = createAsyncThunk<IPaymentStatusData, { tradeNo: string }>(
	'paymentModel/fetchPaymentStatus',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonService.fetchPaymentStatus(params)).unwrap();
		return data;
	}
);
const queryTradeResult = createAsyncThunk<queryTradeResultRes, { tradeNo: string }>(
	'paymentModel/queryTradeResult',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CommonService.queryTradeResult(params)).unwrap();
		return data;
	}
);
const paymentModel = slice({
	name: 'paymentModel',
	initialState: {},
	reducers: {},
	extraReducers: {},
	effect: { fetchPaymentInfo, fetchPaymentStatus, queryTradeResult }
});

export default paymentModel;

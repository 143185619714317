import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IMemberVipListData, MemberVipService } from '$lib/service/member-vip';

import { IMemberVipListState } from './types';

const fetchList = createAsyncThunk('memberVipListModel/vipList', async (_, { dispatch }) => {
	const { data } = await dispatch(MemberVipService.vipList()).unwrap();
	return data;
});
const memberVipListModel = slice({
	name: 'memberVipListModel',
	initialState: {
		list: [],
		hasMore: true
	} as IMemberVipListState,
	reducers: {},
	extraReducers: {
		[fetchList.fulfilled.type]: (state, payload: IMemberVipListData[]) => {
			state.list = payload;
		}
	},
	effect: {
		fetchList
	}
});

export default memberVipListModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ISecondHandForm, SecondHandService } from '$lib/service/second-hand';

import { ISecondHandFormState } from './types';

const submit = createAsyncThunk(
	'secondHandFormModel/submit',
	async (
		options: { type: 'edit' | 'add'; params: ISecondHandForm },
		{ dispatch, rejectWithValue }
	) => {
		const { type, params } = options;
		const serviceName = type === 'add' ? 'addSecondHand' : 'editSecondHand';
		const tParams = Object.assign({}, params);
		tParams.price = Number(params.price ?? 0);
		try {
			await dispatch(SecondHandService[serviceName](tParams)).unwrap();
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const fetchVerifyCode = createAsyncThunk(
	'secondHandFormModel/fetchVerifyCode',
	async (params: { mobile: string }, { dispatch }) => {
		const { data } = await dispatch(SecondHandService.fetchVerifyCode(params)).unwrap();
		return data;
	}
);

const fetchFormValue = createAsyncThunk(
	'secondHandFormModel/fetchFormValue',
	async (params: { id: string | number; userId?: string | number }, { dispatch }) => {
		const { data } = await dispatch(SecondHandService.fetchDetail(params)).unwrap();
		const formValue = {} as ISecondHandFormState['formValue'];
		Object.assign(formValue, {
			id: data.id,
			areaId: data.areaId,
			cityId: data.cityId,
			provinceId: data.provinceId,
			categoryId: data.categoryId,
			userMobile: data.userMobile,
			priceSetting: data.priceSetting,
			rentPriceUnit: data.rentPriceUnit,
			price: data.price,
			transactionType: data.transactionType,
			fleaMarketGoodsFileAddDTOList: data.fileUrl,
			detail: data.detail
		});
		return formValue;
	}
);

const secondHandFormModel = slice({
	name: 'secondHandFormModel',
	initialState: <ISecondHandFormState>{
		formValue: {}
	},
	reducers: {},
	extraReducers: {},
	effect: { submit, fetchVerifyCode, fetchFormValue }
});

export default secondHandFormModel;

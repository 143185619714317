import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IRecordParams, MemberVipService } from '$lib/service/b-member-vip';

import { IMemberVipRecordState } from './types';

/** 会员购买记录 */
const fetchRecordList = createAsyncThunk(
	'memberVipRecordModel/recordList',
	async (params: IRecordParams, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.recordList(params)).unwrap();
		return data;
	}
);

/** 推荐会员列表 */
const fetchRecommendList = createAsyncThunk(
	'memberVipRecordModel/recommendList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(MemberVipService.recommendList()).unwrap();
		return data;
	}
);
const memberVipRecordModel = slice({
	name: 'memberVipRecordModel',
	initialState: {
		recordList: [],
		hasMore: false,
		recordParams: {
			currentPage: 1,
			pageSize: 100
		},
		recommendList: [],
		vipPageList: []
	} as IMemberVipRecordState,
	reducers: {},
	extraReducers: {
		[fetchRecordList.fulfilled.type]: (state, { payload }) => {
			const { data, paginator } = payload;
			state.recordList = paginator?.currentPage === 1 ? data : [...state.recordList, ...data];
			state.recordParams.currentPage = paginator?.currentPage;
			state.hasMore = data?.length >= paginator?.pageSize;
		},
		[fetchRecommendList.fulfilled.type]: (state, { payload }) => {
			state.recommendList = payload;
		}
	},
	effect: {
		fetchRecordList,
		fetchRecommendList
	}
});

export default memberVipRecordModel;

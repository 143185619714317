import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	DepositService,
	ISubmitVerifyCodeParams,
	ISubmitVerifyCodeRes
} from '$lib/service/deposit';

const submit = createAsyncThunk<ISubmitVerifyCodeRes, ISubmitVerifyCodeParams>(
	'verifyCodeModel/submit',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(DepositService.submitVerifyCode(params)).unwrap();

			return data;
		} catch (e) {
			return rejectWithValue(e);
		}
	}
);

const verifyCodeModel = slice({
	name: 'verifyCodeModel',
	initialState: {},
	reducers: {},
	effect: { submit }
});

export default verifyCodeModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { OtherRecruitListData, OtherRecruitListParams, OtherResumeStatusData, OtherResumeStatusParams, PropMallService } from '$lib/service/prop-mall';


const fetchOtherResumeStatus = createAsyncThunk<OtherResumeStatusData, OtherResumeStatusParams>('propsEffectModel/checkOtherResumeStatus',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await dispatch(PropMallService.checkOtherResumeStatus(params)).unwrap();
      return data
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const fetchOtherRecruitList = createAsyncThunk<OtherRecruitListData, OtherRecruitListParams>(
  'propsEffectModel/fetchOtherRecruitList',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await dispatch(PropMallService.fetchOtherRecruitList(params)).unwrap();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const propsEffectModel = slice({
  name: 'propsEffectModel',
  initialState: {} as unknown,
  reducers: {},
  extraReducers: {
  },
  effect: {
    fetchOtherResumeStatus,
    fetchOtherRecruitList
  }
});

export default propsEffectModel;


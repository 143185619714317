import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { YpStoreService } from '$lib/service/yp-store';

import { IYpStoreIndexState } from './types';

/** 获取首页数据 */
const fetchInfo = createAsyncThunk('ypStoreIndexModel/fetchInfo', async (_, { dispatch }) => {
	const { data } = await dispatch(YpStoreService.fetchProductList()).unwrap();
	return data;
});

/** 获取新年活动 */
const fetchNewYearActivity = createAsyncThunk(
	'ypStoreIndexModel/fetchNewYearActivity',
	async (_, { dispatch }) => {
		const { data } = await dispatch(YpStoreService.fetchNewYearActivity()).unwrap();
		return data;
	}
);

/** 获取拼团商品列表 */
const fetchGroupBookingList = createAsyncThunk(
	'ypStoreIndexModel/fetchGroupBookingList',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			YpStoreService.fetchGroupBookingList({ currentPage: 1, pageSize: 3 })
		).unwrap();
		return data.data;
	}
);

/** 获取首页券列表 */
const fetchCoupons = createAsyncThunk('ypStoreIndexModel/fetchCoupons', async (_, { dispatch }) => {
	const { data } = await dispatch(YpStoreService.fetchCoupons({ queryType: 'TEMPLATE' })).unwrap();
	return data;
});

const ypStoreIndexModel = slice({
	name: 'ypStoreIndexModel',
	initialState: <IYpStoreIndexState>(<unknown>{
		activity: [],
		groupBookingList: [],
		info: {},
		coupons: {}
	}),
	reducers: {
		setState(state, { payload }) {
			return { ...state, ...payload } as IYpStoreIndexState;
		}
	},
	extraReducers: {
		[fetchInfo.fulfilled.type]: (state, { payload }) => {
			state.info = payload;
		},
		[fetchNewYearActivity.fulfilled.type]: (state, { payload }) => {
			state.activity = payload;
		},
		[fetchGroupBookingList.fulfilled.type]: (state, { payload }) => {
			state.groupBookingList = payload;
		},
		[fetchCoupons.fulfilled.type]: (state, { payload }) => {
			state.coupons = payload;
		}
	},
	effect: { fetchInfo, fetchNewYearActivity, fetchGroupBookingList, fetchCoupons }
});

export default ypStoreIndexModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ArticleService } from '$lib/service/article';

import { IArticleState } from './types';

export const fetchArticleDetail = createAsyncThunk(
	'articleModel/fetchArticleDetail',
	async (params: { id: number | string }, { dispatch }) => {
		const { data } = await dispatch(ArticleService.fetchArticleDetail(params)).unwrap();
		return data;
	}
);

export const praiseArticle = createAsyncThunk(
	'articleModel/praiseArticle',
	async (params: { id: number | string }, { dispatch }) => {
		await dispatch(ArticleService.praiseArticle(params)).unwrap();
		return params.id;
	}
);

const articleModel = slice({
	name: 'articleModel',
	initialState: {
		praiseList: [],
		articleInfo: {}
	} as IArticleState,
	reducers: {
		setArticleInfo: (state, { payload }) => {
			state.articleInfo = payload;
		}
	},
	extraReducers: {
		[fetchArticleDetail.fulfilled.type]: (state, { payload }) => {
			state.articleInfo = payload;
		},
		[praiseArticle.fulfilled.type]: (state) => {
			state.praiseList = [...state.praiseList, state?.articleInfo?.articleId ?? ''];
			state.articleInfo.likeCount = (state?.articleInfo?.likeCount ?? 0) + 1;
		}
	},
	effect: {
		fetchArticleDetail,
		praiseArticle
	}
});

export const subtrees = ['articleModel.praiseList'];
export default articleModel;

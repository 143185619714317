import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { ComplainService } from '$lib/service/complain';
import { IFetchComplainDetailReq } from '$lib/service/complain/types';

const INIT = {
	files: [],
	audit: '',
	auditedAt: '',
	id: 0,
	uuid: '',
	content: '',
	bizName: '',
	type: '',
	submitAt: '',
	status: 1,
	read: false
};
export const fetchComplainDetails = createAsyncThunk(
	'complainDetailModel/fetchComplainDetails',
	async (params: IFetchComplainDetailReq, { dispatch }) => {
		const { data } = await dispatch(ComplainService.fetchComplainDetails(params)).unwrap();
		return data;
	}
);

const complainDetailModel = slice({
	name: 'complainDetailModel',
	initialState: {
		detailInfo: INIT
	},
	reducers: {
		setInit(state) {
			state.detailInfo = INIT;
		}
	},
	extraReducers: {
		[fetchComplainDetails.fulfilled.type]: (state, { payload }) => {
			state.detailInfo = payload;
		}
	},
	effect: {
		fetchComplainDetails
	}
});

export default complainDetailModel;

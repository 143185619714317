import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { BaseParams, PayRefundService, ShortLinkCheckReq, UserPaymentProofReq, UserRealNameConfirmReq } from '$lib/service/pay-refund';

import { PayRefundState } from './types';

export const IApplyOrderCheckPaymentProof = createAsyncThunk<Boolean, BaseParams>(
	'payRefundModel/IApplyOrderCheckPaymentProof',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PayRefundService.IApplyOrderCheckPaymentProof(params)).unwrap();
		return data;
	})

export const IUserRealNameConfirm = createAsyncThunk<void, UserRealNameConfirmReq>(
	'payRefundModel/IUserRealNameConfirm',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PayRefundService.IUserRealNameConfirm(params)).unwrap();
		return data;
	})

export const IShortLinkCheck = createAsyncThunk<void, ShortLinkCheckReq>(
	'payRefundModel/IShortLinkCheck',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PayRefundService.IShortLinkCheck(params)).unwrap();
		return data;
	})

export const IApplyOrderUserPaymentProof = createAsyncThunk<void, UserPaymentProofReq>(
	'payRefundModel/IApplyOrderUserPaymentProof',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PayRefundService.IApplyOrderUserPaymentProof(params)).unwrap();
		return data;
	})


const PayRefund = slice({
	name: 'payRefundModel',
	initialState: <PayRefundState>{

	},
	reducers: {
		setState(state, { payload }) { }
	},
	extraReducers: {
	},
	effect: {
		IApplyOrderCheckPaymentProof,
		IUserRealNameConfirm,
		IShortLinkCheck,
		IApplyOrderUserPaymentProof
	},
});


export default PayRefund;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { RecordListReq, WithdrawalService } from '$lib/service/withdrawal';

/** 获取余额信息 */
const getAccountMoney = createAsyncThunk(
	'getRcordModel/getAccountMoney',
	async (params: { active_id: string }, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getAccountMoney(params)).unwrap();

		return data;
	}
);

/** 获取提现记录列表 */
const getRecordList = createAsyncThunk(
	'getRcordModel/getRecordList',
	async (params: RecordListReq, { dispatch }) => {
		const { data } = await dispatch(WithdrawalService.getRecordList(params)).unwrap();
		return data;
	}
);

const getRcordModel = slice({
	name: 'getRcordModel',
	initialState: {},
	reducers: {},
	effect: { getAccountMoney, getRecordList }
});

export default getRcordModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IComplaintCategoryConfig, SelectClassificationNewService } from '$lib/service/select-classification-new';

import { SelectClassificationNewState } from './types';


export const fetchComplaintCategoryConfig = createAsyncThunk<IComplaintCategoryConfig, { projectId: number }>(
	'selectClassificationNew/fetchComplaintCategoryConfig',
	async (params, { dispatch }) => {
		const { data } = await dispatch(SelectClassificationNewService.fetchComplaintCategoryConfig(params)).unwrap();
		return data;
	}
);

const SelectClassificationNew = slice({
	name: 'selectClassificationNewModel',
	initialState: <SelectClassificationNewState>{
		complaintCategoryConfig: {
		},
	},
	reducers: {
		setState(state, { payload }) { }
	},
	extraReducers: {
		[fetchComplaintCategoryConfig.fulfilled.type]: (state, { payload }) => {
			state.complaintCategoryConfig = payload;
		},
	},
	effect: {
		fetchComplaintCategoryConfig,

	},
});


export default SelectClassificationNew;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	ICreateMembersOrderParams,
	ICreateMembersOrderRes,
	IPurchaseResultRes,
	TeamMembersService
} from '$lib/service/team-members';
/**
 * 提交订单
 */
const submit = createAsyncThunk<ICreateMembersOrderRes, ICreateMembersOrderParams>(
	'teamMembersPurchaseModel/submit',
	async (params, { dispatch }) => {
		const { data } = await dispatch(TeamMembersService.createOrder(params)).unwrap();
		return data;
	}
);

/**
 * 获取支付结果
 */
const fetchPurchaseResult = createAsyncThunk<IPurchaseResultRes, string>(
	'teamMembersPurchaseModel/fetchPurchaseResult',
	async (tradeNo, { dispatch }) => {
		const { data } = await dispatch(TeamMembersService.fetchPurchaseResult({ tradeNo })).unwrap();
		return data;
	}
);

/**
 * 班组VIP购买页model
 */
const teamMembersPurchaseModel = slice({
	name: 'teamMembersPurchaseModel',
	initialState: {},
	reducers: {},
	effect: { submit, fetchPurchaseResult }
});

export default teamMembersPurchaseModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IConfirmContinueReq,
	IGetCurrentRiddleReq,
	LanternFestivalService
} from '$lib/service/lantern-festival';

import { ILanternFestivalState } from './types';

/** 获取答题中的灯谜 */
export const getCurrentRiddle = createAsyncThunk(
	'lanternFestivalModel/getCurrentRiddle',
	async (params: IGetCurrentRiddleReq, { dispatch }) => {
		const { data } = await dispatch(LanternFestivalService.getCurrentRiddle(params)).unwrap();
		return data;
	}
);

/** 确认继续答题 */
export const confirmContinue = createAsyncThunk(
	'lanternFestivalModel/confirmContinue',
	async (params: IConfirmContinueReq, { dispatch }) => {
		const { data } = await dispatch(LanternFestivalService.confirmContinue(params)).unwrap();
		return data;
	}
);

const LanternFestivalModel = slice({
	name: 'lanternFestivalModel',
	initialState: {
		/** 谜题数据 信息*/
		getCurrentRiddleData: {
			id: 0,
			status: '',
			helpStatus: '',
			sn: '',
			rewardType: 0,
			answerExpireTtl: 20,
			riddles: [],
			headImg: '',
			failedReason: 2
		}
	} as ILanternFestivalState,
	reducers: {
		updateUserImg: (state, { payload }) => {
			state.getCurrentRiddleData.headImg = payload;
		}
	},
	extraReducers: {
		[getCurrentRiddle.fulfilled.type]: (state, { payload }) => {
			state.getCurrentRiddleData = payload;
		},
		[confirmContinue.fulfilled.type]: (state, { payload }) => {
			/** 替换当前题目 */
			const temp = state.getCurrentRiddleData.riddles;
			temp?.splice(payload?.number, 1, payload);
			state.getCurrentRiddleData.riddles = temp;
		}
	},
	effect: {
		getCurrentRiddle,
		confirmContinue
	}
});

export default LanternFestivalModel;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { dialog } from '$components/shared/dialog';
import { slice } from '$lib/model/utils';
import { ReportCentreService } from '$lib/service/report-centre';

// import { IReportState } from './types';

const submitScore = createAsyncThunk(
	'reportCentreResultsModel/submitScore',
	async ({score, id, type}: {score?: number, id: number, type: string}, { dispatch }) => {
		try {
			const { data } = await dispatch(ReportCentreService.evaluationStars({ score, id, reportType: type })).unwrap();
			return data;
		} catch(e) {
			return {};
		}
	}
)

const getReportResult = createAsyncThunk(
	'reportCentreResultsModel/getReportResult',
	async ({ id, reportType }: { id: string, reportType: string }, { dispatch }) => {
		try {
			const { data } = await dispatch(ReportCentreService.reportResult({ id, reportType })).unwrap();
			return data;
		} catch(e) {
			return {};
		}
	}
)

const initResults = {
	date: null,
	result: '',
	name: '',
	avatar: '',
	content: '',
	images: [],
}

const reportCentreResultsModel = slice({
	name: 'reportCentreResultsModel',
	initialState: {
		results: initResults,
		starred: true,
		score: 0,
	},
	reducers: {
		reset(state) {
			state.results = initResults;
			state.starred = true;
			state.score = 0;
		},
		setScore(state, { payload }) {
			const score = payload === state.score ? 0 : payload;
			state.score = score;
		},
	},
	extraReducers: {
		[submitScore.fulfilled.type]: (state, { payload }) => {
			state.starred = !!payload;
			dialog.show({
				title: '温馨提示',
				content: '感谢您的反馈，我们将竭诚为您提供更优质的服务！',
				hideCancelButton: true,
				confirmButtonText: '我知道了'
			});
		},
		[getReportResult.fulfilled.type]: (state, { payload }) => {
			const {
				handDate: date,
				context: content,
				handContext: result,
				userName: name,
				headPortrait: avatar,
				evaluate: starred,
				images } = payload;
			state.results = {
				date,
				result,
				name,
				avatar,
				content,
				images,
			};
			state.starred = starred;
		},
	},
	effect: { submitScore, getReportResult },
});

export default reportCentreResultsModel;

import { createAsyncThunk } from '@reduxjs/toolkit';
import router from 'next/router';

import { dialog } from '$components/shared/dialog';
// import moment from 'moment';
import { slice } from '$lib/model/utils';
import {
	IReportCentreInfoForm,
	IResponseRadio,
	ReportCentreService
} from '$lib/service/report-centre';

type IState = {
	avatar: string | null;
	name: string | null;
	date: string | null;
	radios?: [];
	isWorker: boolean | null;
	workTags?: string[];
	content?: string;
};

type IInfoDetailState = {
	data: IState;
	radios: IResponseRadio[];
	callService: boolean;
};

const getConfig = createAsyncThunk(
	'reportCentreInfoDetailModel/getConfig',
	async (id: string | number, { dispatch }) => {
		try {
			const { data } = await dispatch(
				ReportCentreService.reportInfoCategory({ projectId: id })
			).unwrap();
			return data;
		} catch (e) {
			return {};
		}
	}
);

/** 点击联系客服 */
const contactCustomer = (dispatch: any) => {
	dispatch(reportCentreInfoDetailModel.actions.openCall());
	router.back();
};

const submitReport = createAsyncThunk(
	'reportCentreInfoDetailModel/submitReport',
	async (data: IReportCentreInfoForm, { dispatch }) => {
		try {
			const res = await dispatch(ReportCentreService.submitInfoForm(data)).unwrap();

			dialog.show({
				title: '温馨提示',
				content:
					'感谢您的反馈，我们将在工作时间 24 小时内为您处理，请耐心等待，如有疑问请联系人工客服，客服电话：400-838-1888',
				confirmButtonText: '返回',
				cancelButtonText: '联系客服',
				onCancel: contactCustomer.bind(this, dispatch),
				onConfirm: router.back
			});
			return res.data;
		} catch (e) {
			return {};
		}
	}
);

const initState: IState = {
	avatar: null,
	name: null,
	date: null,
	radios: [],
	isWorker: null,
	workTags: [],
	content: ''
};

const reportCentreInfoDetailModel = slice({
	name: 'reportCentreInfoDetailModel',
	initialState: <IInfoDetailState>{ data: initState, radios: [], callService: false },
	reducers: {
		reset(state) {
			state.data = initState;
		},
		openCall(state) {
			state.callService = true;
		},
		closeCall(state) {
			state.callService = false;
		}
	},
	extraReducers: {
		[getConfig.fulfilled.type]: (state, { payload }: { payload: { list: [IResponseRadio] } }) => {
			state.radios = payload?.list[0]?.childNode || [];
			// if (Math.random() > 0.5) {
			// 	state.data = workerMock;
			// } else {
			// 	state.data = traderMock;
			// }
		}
	},
	effect: { getConfig, submitReport }
});

export default reportCentreInfoDetailModel;

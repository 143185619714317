import { IDepositConfig } from '$lib/service/deposit';

export const DEFAULT_CONFIG: IDepositConfig = {
	name: '',
	depositType: 0,
	depositAmount: 0,
	compensateType: 0,
	compensateAmount: 0,
	status: 1,
	configs: {}
};

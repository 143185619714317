import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import { IMemberVipOrderRes, IosRmbBuyLimitRiskCheck } from '$lib/service/b-member-vip';
import { CreateChargeOrderReq, OrderListIapProductsReq, OrderListIapProductsRes, PointsRechargeGuideService } from '$lib/service/points-recharge-guide';

import { PointsRechargeGuideState } from './types';

export const IAPListIapProducts = createAsyncThunk<OrderListIapProductsRes, void>(
	'pointsRechargeGuideModel/IAPListIapProducts',
	async (_, { dispatch }) => {
		const { data } = await dispatch(PointsRechargeGuideService.IAPListIapProducts()).unwrap();
		return data;
	})

export const IAPCheckPayRisk = createAsyncThunk<Partial<IosRmbBuyLimitRiskCheck>, OrderListIapProductsReq>(
	'pointsRechargeGuideModel/IAPCheckPayRisk',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PointsRechargeGuideService.IAPCheckPayRisk(params)).unwrap();
		return data;
	})

export const IAPCreateChargeOrder = createAsyncThunk<IMemberVipOrderRes, CreateChargeOrderReq>(
	'pointsRechargeGuideModel/IAPCreateChargeOrder',
	async (params, { dispatch }) => {
		const { data } = await dispatch(PointsRechargeGuideService.IAPCreateChargeOrder(params)).unwrap();
		return data;
	})


const PointsRechargeGuide = slice({
	name: 'pointsRechargeGuideModel',
	initialState: <PointsRechargeGuideState>{
		goodsList: [],

	},
	reducers: {
		setState(state, { payload }) { }
	},
	extraReducers: {
		[IAPListIapProducts.fulfilled.type]: (state, { payload }) => {
			state.goodsList = payload.products;
		}
	},
	effect: {
		IAPListIapProducts,
		IAPCheckPayRisk,
		IAPCreateChargeOrder
	},
});


export default PointsRechargeGuide;

import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
	IMembersGoodInfoRes,
	IMembersStatusRes,
	ITeamMembersConfigRes,
	TeamMembersService
} from '$lib/service/team-members';

import { ITeamMemberState } from './types';

/**
 * 获取会员配置信息
 */
const fetchMembersConfig = createAsyncThunk<ITeamMembersConfigRes>(
	'teamMembersModel/fetchMembersConfig',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			TeamMembersService.fetchMembersConfig({ vipType: 1001 })
		).unwrap();
		return data;
	}
);

/**
 * 获取会员商品信息
 */
const fetchMemberGoodInfo = createAsyncThunk<IMembersGoodInfoRes>(
	'teamMembersModel/fetchMemberGoodInfo',
	async (_, { dispatch }) => {
		const { data } = await dispatch(
			TeamMembersService.fetchMemberGoodInfo({ goodsCode: 'TEAMS_GROUP_VIP_30_DAY' })
		).unwrap();
		return data;
	}
);

/**
 * 获取会员状态
 */
const fetchMembersStatus = createAsyncThunk<IMembersStatusRes, number>(
	'teamMembersModel/fetchMembersStatus',
	async (uid: number, { dispatch }) => {
		const { data } = await dispatch(
			TeamMembersService.fetchMembersStatus({ vipType: 1001, uid })
		).unwrap();
		return data;
	}
);

/**
 * 班组VIP model
 */
const teamMembersModel = slice({
	name: 'teamMembersModel',
	initialState: <ITeamMemberState>{
		memberInfo: {},
		goodInfo: {},
		membersStatus: {}
	},
	reducers: {},
	extraReducers: {
		[fetchMembersConfig.fulfilled.type]: (state, { payload }) => {
			state.memberInfo = payload;
		},
		[fetchMemberGoodInfo.fulfilled.type]: (state, { payload }) => {
			state.goodInfo = payload;
		},
		[fetchMembersStatus.fulfilled.type]: (state, { payload }) => {
			state.membersStatus = payload;
		}
	},
	effect: { fetchMembersConfig, fetchMemberGoodInfo, fetchMembersStatus }
});

export default teamMembersModel;

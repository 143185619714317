import { createAsyncThunk } from '@reduxjs/toolkit';

import { slice } from '$lib/model/utils';
import {
} from '$lib/service/credits';
import { CustomerService, ICorpTransferAccountData, IPrePayData, IPrePayParams } from '$lib/service/customer';

import { ICustomerRechargeState } from './types';

const fetchCustomerInfo = createAsyncThunk<ICustomerRechargeState['config'], { orderNo: string }>(
	'customerRechargeModel/fetchCustomerInfo',
	async (params, { dispatch, rejectWithValue }) => {
		try {
			const { data } = await dispatch(CustomerService.getOrderInfo(params)).unwrap();
			return data
		} catch (e: any) {
			return rejectWithValue(e);
		}
	}
);

const submit = createAsyncThunk<IPrePayData, IPrePayParams>(
	'customerRechargeModel/submit',
	async (params, { dispatch }) => {
		const { data } = await dispatch(CustomerService.submit(params)).unwrap();
		return data;
	}
);

const getCorpTransferAccount = createAsyncThunk<ICorpTransferAccountData>(
	'customerRechargeModel/getCorpTransferAccount',
	async (_, { dispatch }) => {
		const { data } = await dispatch(CustomerService.getCorpTransferAccount()).unwrap();
		return data;
	}
);

const customerRechargeModel = slice({
	name: 'customerRechargeModel',
	initialState: <ICustomerRechargeState>{
		config: {},
		transferAccount: {}
	},
	reducers: {},
	extraReducers: {
		[fetchCustomerInfo.fulfilled.type]: (state, { payload }) => {
			state.config = payload
		},
		[getCorpTransferAccount.fulfilled.type]: (state, { payload }) => {
			state.transferAccount = payload
		}
	},
	effect: { fetchCustomerInfo, submit, getCorpTransferAccount }
});

export default customerRechargeModel;

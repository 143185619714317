import { slice } from '$lib/model/utils';

const secondHandSearchModel = slice({
	name: 'secondHandSearchModel',
	initialState: <{ history: string[] }>{
		history: []
	},
	reducers: {
		setHistory(state, { payload }: { payload: string | [] }) {
			if (Array.isArray(payload)) {
				state.history = [];
			} else {
				if (state.history.length >= 5) {
					state.history.splice(state.history.length - 1, 1);
				}
				const arr = state?.history?.length
					? state?.history?.filter((each) => each !== payload?.trim())
					: [];
				state.history = [payload].concat(arr);
			}
		}
	},
	extraReducers: {},
	effect: {}
});

export const persistTrees = [{ key: 'secondHandSearchModel.history', type: 'local' }];

export default secondHandSearchModel;
